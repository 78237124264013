
// import { ethers } from 'hardhat';
import localforage from 'localforage';
import { ethers } from 'ethers';

// import React from 'react'
import AFWalletRPCProvider from './AFWalletRPCProvider';
import { WalletUIStatus } from './AFWalletUI';
import { EthereumChains, PlexiMailStatus, StorageProvider, Web3StorageAccessMethod } from '../utils/Types';
import AFWalletRPCError from './AFWalletRPCError';

import { HDKey } from "ethereum-cryptography/hdkey";
// import { wordlist } from "ethereum-cryptography/bip39/wordlists/english";
import * as bip39 from "ethereum-cryptography/bip39";
import { keys } from 'libp2p-crypto';

import plexMailRootRegistry from '../PlexiMailRootRegistryABI.json';
import BackupService from './service/BackupService';
import { ClientError/*, showWarning*/ } from '../common/errors';

import * as CurrencyUtils from '../utils/CurrencyUtils';
// import * as TorUtils from '../utils/TorUtils';

import WalletStrings from './WalletStrings';
import AppConfig from '../config/AppConfig'
const fromHexString = (hexString) => {
    return Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
}

export default class AFWallet {

    static #store_key_prefix = 'afwallet.';

    static #store_keys = {
        wallets: AFWallet.#store_key_prefix + 'wallets',
        id: AFWallet.#store_key_prefix + 'id',
        wallet: AFWallet.#store_key_prefix + 'wallet',
        rpcProviderType: AFWallet.#store_key_prefix + 'rpcProviderType',
        password: AFWallet.#store_key_prefix + 'password',
        isBackup: AFWallet.#store_key_prefix + 'isBackup',
        agent: AFWallet.#store_key_prefix + 'agent',
    };

    static RPCProviderType = {
        Unset: 0,
        AFWallet: 1,
        Other: 2,
    };

    static counter = 0;
    instanceNo = 0;
    #rpcProvider = null;
    disclosure = null;
    setStatus = null;
    setVersion = null;
    #version = 0;
    #store = null;
    #inMemoryStore = {};
    #ethersProviders = null;


    #backupService = null;
    #abi = null;
    #pin = null;

    #pendingRequest = null;
    #rpcProviderType = 0;

    #activatedWallet = null;
    #accountName = null;

    constructor(disclosure, setStatus, setVersion) {
        this.#rpcProvider = new AFWalletRPCProvider(this);
        this.disclosure = disclosure;
        this.setStatus = setStatus;
        this.setVersion = setVersion;
        this.#backupService = new BackupService();
        try {
            const store = localforage.createInstance({
                driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
                name        : 'afwallet',
                version     : 3,
                // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
                storeName   : 'wallet', // Should be alphanumeric, with underscores.
                description : 'data of wallet'
            });
            // localforage.config({
            //     driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
            //     name        : 'afwallet',
            //     version     : 3,
            //     // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
            //     storeName   : 'wallet', // Should be alphanumeric, with underscores.
            //     description : 'data of wallet'
            // });
            // const store = localforage;
            this.#store = store;
        } catch (e) {
            console.error(e);
        }

        const goerliEthersProvider = new ethers.InfuraProvider('goerli', '3755e24b21be49618434ff544763d7ce');
        const sepoliaEthersProvider = new ethers.InfuraProvider('sepolia', '3755e24b21be49618434ff544763d7ce');
        // const mainEthersProvider = new ethers.InfuraProvider('mainnet', '3755e24b21be49618434ff544763d7ce');
        const fujiEthersProvider = new ethers.JsonRpcProvider('https://api.avax-test.network/ext/bc/C/rpc');
        const avalancheEthersProvider = new ethers.JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc');
        this.#ethersProviders = {
            // mainnet: mainEthersProvider,
            sepolia: sepoliaEthersProvider,
            goerli: goerliEthersProvider,
            fuji: fujiEthersProvider,
            avalanche: avalancheEthersProvider,
            mainnet: avalancheEthersProvider,
        };
        this.instanceNo = AFWallet.counter++;
        console.log('AFWallet - constructor: ', AFWallet.counter);
        this.#abi = ethers.Interface.from(plexMailRootRegistry.abi);
    }

    async #getItem(key) {
        let data = null;
        if (window.torInfo.isIndexedDBSupported) {
            data = (window.appConfig.privacyLevelIsNormal) ? await this.#store.getItem(key) : this.#inMemoryStore[key];
        } else {
            data = this.#inMemoryStore[key];;
        }
        if(!data)
            return null;
    
        const { expire, value } = data;
    
        if(expire && expire < Date.now()){
            if (window.appConfig.privacyLevelIsNormal) {
                this.#store.removeItem(key);
            } else {
                delete this.#inMemoryStore[key];
            }
            return null;
        }
        
        return value;
    
    }

    async #setItem(key, value, expire = false, callback = false) {

        if(expire && typeof expire === 'number')
            expire = Math.round(expire * 1000 + Date.now()); // * 1000 to use seconds

        if (window.torInfo.isIndexedDBSupported) {
            
            if (window.appConfig.privacyLevelIsNormal) {
                return await this.#store.setItem(key, { value, expire }, expire && callback);
            } else {
                this.#inMemoryStore[key] = { value, expire };
            }
        } else {
            this.#inMemoryStore[key] = { value, expire };

        }
    }


    #setPin(pin) {
        if (window.appConfig.isConfigured) {
            this.#pin = pin;
        } else {
            if (window.appConfig.privacyLevelIsNormal) {
                sessionStorage.setItem(AFWallet.#store_keys.password, pin);
            } else {
                this.#pin = pin;
            }
        }
    }

    #getPin() {
        if (window.appConfig.isConfigured) {
            return this.#pin;
        } else {
            if (window.appConfig.privacyLevelIsNormal) {
                const pin = sessionStorage.getItem(AFWallet.#store_keys.password);
                return pin || this.#pin;
            } else {
                return this.#pin;
            }
        }
    }

    async #mnemonicToSeed(mnemonic) {
        return bip39.mnemonicToSeed(mnemonic)
    }
    
    async #hdKeyFromMnemonic(mnemonic) {
        const seed = await this.#mnemonicToSeed(mnemonic.phrase || mnemonic);
        const promise = new Promise((resolve, reject) => {
            try {
                const hdKey = HDKey.fromMasterSeed(seed);
                resolve(hdKey);
            } catch (e) {   
                reject(e);
            }
        });
        return promise;
    }



    get store() {
        return this.#store;
    }

    get rpcProvider() {
        return this.#rpcProvider;
    }

    get pendingRequest() {
        return this.#pendingRequest ? this.#pendingRequest.request : null;
    }

    get address() {
        return this.#activatedWallet ? this.#activatedWallet.wallet.address : null;
    }

    async isConfigured() {
        const wallets = await this.getWalletList();
        return (wallets !== null && wallets.length !== 0);
    }
    async hasMultipleAccounts() {
        const wallets = await this.getWalletList();
        return (wallets !== null && wallets.length > 1);
    }

    async isUnlocked() {
        if (this.#activatedWallet) {
            return true;
        }
        const pin = await this.#getPin();
        return (pin !== null && pin.length !== 0)
        // return this.#activatedWallet !== null;
    }

    async isReady() {
        const isConf = await this.isConfigured();
        const isUnlocked = this.isUnlocked();
        return isConf && isUnlocked;
    }
    isConnected() {
        return false;
    }

    async #keyAtPathWithRootKey(rootKey, path, encryption=false) {
        const hdKey = rootKey.derive(path);
        if (encryption) {
            const key = await window.crypto.subtle.digest('SHA-256', hdKey.privateKey);
            const aesKey = await window.crypto.subtle.importKey('raw', key, 'AES-GCM', true, ['encrypt', 'decrypt']);
            return aesKey;
        } else {
            const seedBuffer = await window.crypto.subtle.digest('SHA-256', hdKey.privateKey);
            const seed = new Uint8Array(seedBuffer);
            const key = await keys.generateKeyPairFromSeed('Ed25519', seed, 1024);
            return key;
        }
    }
    async hashAtKeyPath(path) {
        const hdKey = this.#activatedWallet.hdKey.derive(path);
        const key = await window.crypto.subtle.digest('SHA-256', hdKey.privateKey);
        return new Uint8Array(key);
    }
    async keyAtPath(path, encryption=false) {
        const hdKey = this.#activatedWallet.hdKey.derive(path);
        if (encryption) {
            const key = await window.crypto.subtle.digest('SHA-256', hdKey.privateKey);
            const aesKey = await window.crypto.subtle.importKey('raw', key, 'AES-GCM', true, ['encrypt', 'decrypt']);
            return aesKey;
        } else {
            const seedBuffer = await window.crypto.subtle.digest('SHA-256', hdKey.privateKey);
            const seed = new Uint8Array(seedBuffer);
            const key = await keys.generateKeyPairFromSeed('Ed25519', seed, 1024);
            return key;
        }
    }

    async setRpcProviderType(type) {
        if (window.appConfig.privacyLevelIsNormal) {
            localStorage.setItem(AFWallet.#store_keys.rpcProviderType, type);
        }
        this.#rpcProviderType = type;
    }

    get asDefaultWallet() {
        return this.rpcProviderType() === AFWallet.RPCProviderType.AFWallet;
    }

    rpcProviderType() {
        if (this.#rpcProviderType !== AFWallet.RPCProviderType.Unset) {
            return this.#rpcProviderType;
        }
        if (!window.appConfig.privacyLevelIsNormal) {
            return this.#rpcProviderType;
        }
        
        const type = localStorage.getItem(AFWallet.#store_keys.rpcProviderType) || '';
        const typeNumber = type === '' ? AFWallet.RPCProviderType.Unset : parseInt(type);
        this.#rpcProviderType = typeNumber;
        return typeNumber;
    }

    get network() {
        if (window.chainId === EthereumChains.Mainnet) {
            return 'mainnet';
        } else if (window.chainId === EthereumChains.Goerli) {
            return 'goerli';
        } else if (window.chainId === EthereumChains.Sepolia) {
            return 'sepolia';
        } else if (window.chainId === EthereumChains.Fuji) {
            return 'fuji';
        } else if (window.chainId === EthereumChains.Avalanche) {
            return 'avalanche';
        }
        throw AFWalletRPCError.chainIDNotSupported;
    }

    get activatedWallet() {
        return this.#activatedWallet && this.#activatedWallet.wallet;
    }

    get backupWallet() {
        if (!this.#activatedWallet) {
            return false;
        }
        
        let wallet = this.#activatedWallet.wallet.deriveChild(101);
        // const seed=ethers.getBytes(wallet.privateKey);
        // const hdWallet = ethers.HDNodeWallet.fromSeed(seed);
        // console.log(hdWallet.address);
        
        // let newWallet = wallet.derivePath("m/44'/60'/0'/0/0")
        // console.log(newWallet.address)
        return wallet;
    }
    async generateAddress(lastIndex = 0) {
        
        let wallet = this.#activatedWallet.wallet.deriveChild(++lastIndex + 300);
        
        return {lastIndex, address: wallet.address.toLowerCase()};
    }

    async signByWallet(index, address, message) {
        let wallet = this.#activatedWallet.wallet.deriveChild(index + 300);
        if (wallet.address.toLowerCase() !== address) {
            throw new Error('invalid signer');
        }
        const signature = await wallet.signMessage(message);
        return signature;
    }

    get etherProvider() {
        return this.#ethersProviders[this.network];
    }
    get signer() {
        return this.#activatedWallet && this.#activatedWallet.signer;
    }

    get backupService() {
        return this.#backupService;
    }
    async saveWallet(address, walletJSON) {
        address = address.toLowerCase();
        await this.#setItem(AFWallet.#store_keys.wallet + '.' + address, walletJSON);
    }
    async getWalletFromDB(address) {
        address = address.toLowerCase();
        const walletJSON = await this.#getItem(AFWallet.#store_keys.wallet + '.' + address);
        return walletJSON;
    }
    async getWallet(address, pin = null) {
        if (!pin) {
            pin = this.#getPin();
        }
        if (!pin || pin.length === 0) {
            return null;
        }
        address = address.toLowerCase();
        const walletJSON = await this.getWalletFromDB(address);
        if (!walletJSON || walletJSON.length === 0) {
            return null;
        }
        const wallet = await ethers.Wallet.fromEncryptedJson(walletJSON, pin);
        return wallet;
    }

    removePinFromSessionStorageIfNeeded() {
        if (!this.#pin || this.#pin.length === 0) {
            const pin = sessionStorage.getItem(AFWallet.#store_keys.password);
            if (pin && pin.length > 0) {
                this.#pin = pin;
            }
        }
        
        // this.#pin = this.#getPin();
        if (window.appConfig.privacyLevelIsNormal) {
            sessionStorage.removeItem(AFWallet.#store_keys.password);
        }
    }
    
    async switchWallet(address, notify=true) {
        const pin = this.#getPin();
        if (!pin || pin.length === 0) {
            throw new Error('Wallet is locked, please provide PIN Code.');
        }
        const wallet = await this.getWallet(address);
        if (!wallet) {
            return;
        }
        const hdKey = await this.#hdKeyFromMnemonic(wallet.mnemonic);

        const signers = {};
        const networks = Object.keys(this.#ethersProviders);
        
        for(const network of networks) {
            const signer = wallet.connect(this.#ethersProviders[network]);
            signers[network] = signer;
        }


        this.#activatedWallet = {wallet, hdKey, signers};
        const activatedWalletSummary = await this.getActivatedWallet();
        if (!activatedWalletSummary || !activatedWalletSummary.address || activatedWalletSummary.address.toLowerCase() !== address.toLowerCase()) {
            await this.activateWallet(address)
            if (notify) {
                const addresses = await this.getOrderedSelectedAddresses();
                this.#rpcProvider.emit('accountsChanged', addresses)
            }
        }
    }

    async getSigner(address) {
        const network = this.network;
        
        if (this.#activatedWallet && this.#activatedWallet.wallet && this.#activatedWallet.wallet.address && this.#activatedWallet.wallet.address.toLowerCase() === address.toLowerCase()) {
            const signer = this.#activatedWallet.signers[network];
            return signer;
        }

        const isSelected = await this.walletIsSelected(address)
        if (!isSelected) {
            return null;
        }

        const wallet = await this.getWallet(address);
        if (!wallet) {
            return null;
        }
        const signer = wallet.connect(this.#ethersProviders[network]);
        return signer;
    }
    async getActivatedWalletName() {
        if (this.#accountName && this.#accountName.length > 0) {
            return this.#accountName;
        }
        const w = await this.getActivatedWallet();
        if (!w) {
            return null;
        }
        return w.name;
    }
    async getWalletList() {
        let wallets = await this.#getItem(AFWallet.#store_keys.wallets) || [];
        return wallets;
    }
    async walletListIsEmpty() {
        const wallets = await this.getWalletList();
        if (!wallets || wallets.length === 0) {
            return true
        }
        return false;
    }
    async saveWalletList(wallets) {
        await this.#setItem(AFWallet.#store_keys.wallets, wallets);
    }
    async addToWalletList(wallet) {
        const wallets = await this.getWalletList() || [];
        let updated = false;
        for(const w of wallets) {
            if (w.address.toLowerCase() === wallet.address.toLowerCase()) {
                updated = true;
                w.name = wallet.name;
                w.selected = wallet.selected
                w.activated = wallet.activated;
                break;
            }
        }

        if (!updated) {
            wallets.push(wallet);
        }
        await this.saveWalletList(wallets);
    }
    async changeWalletName(address, name) {
        address = address.toLowerCase();
        const wallets = (await this.getWalletList() || []).filter(w => {
            return (w.address && w.address.toLowerCase() === address);
        });

        if (!wallets && wallets.length === 0) {
            return;
        }

        const oldName = wallets[0].name;
        wallets[0].name = name;
        await this.addToWalletList(wallets[0]);

        try {
            await this.showReBackup(WalletStrings.ui.backup.name_changed, false, true);
            if (this.onNameChange) {
                this.onNameChange(name);
            }
        } catch (e) {
            wallets[0].name = oldName;
            await this.addToWalletList(wallets[0]);
            throw e;
        }

        await this.showUI();
    }

    async getSelectedAddresses() {
        const selectedWallets = await this.getSelectedWallets();
        if (!selectedWallets) {
            return null;
        }
        const selectedAddresses = selectedWallets.map(w => {
            return w.address;
        });
        return selectedAddresses;
    }

    async getSelectedWallets() {
        const wallets = await this.getWalletList();
        if (!wallets || wallets.length === 0) {
            return null;
        }

        const selectedWallets = wallets.filter(w => w.selected);
        if (!selectedWallets || selectedWallets.length === 0) {
            return null;
        }
        return selectedWallets;
    }

    async walletIsSelected(address) {
        address = address.toLowerCase();
        const wallets = await this.getWalletList();
        const arr = wallets.filter( w => (w.selected && w.address.toLowerCase() === address))
        if (arr && arr.length > 0) {
            return true;
        }
        return false;
    }

    async selectWallets(addresses) {
        addresses = addresses.map(a => {
            return a.toLowerCase();
        });

        const wallets = await this.getWalletList();
        wallets.forEach(w => {
            w.selected = false;
            for(const address of addresses) {
                if (w.address.toLowerCase() === address) {
                    w.selected = true;
                }
            }
        });
        await this.saveWalletList(wallets);
    }

    async getActivatedAddress() {
        const activatedWallet = await this.getActivatedWallet();
        if (!activatedWallet) {
            return null;
        }
        return activatedWallet.address;
    }

    async getActivatedWallet() {
        const selectedWallets = await this.getSelectedWallets();
        if (!selectedWallets || selectedWallets.length === 0) {
            return null;
        }
        const activatedWallets = selectedWallets.filter(w => w.activated);
        if (!activatedWallets || activatedWallets.length === 0) {
            return null;
        }
        return activatedWallets[0];
    }

    async activateWallet(address) {
        address = address.toLowerCase();
        const wallets = await this.getWalletList();
        wallets.forEach(w => {
            if (w.address.toLowerCase() === address) {
                w.activated = true;
                w.selected = true; 
            } else {
                w.activated = false;
            }
        });
        await this.saveWalletList(wallets);
    }

    async getOrderedSelectedAddresses() {
        const wallets = await this.getOrderedSelectedWallets();
        if (!wallets) {
            return null;
        }
        return wallets.map(w => w.address);
    }
    async getOrderedSelectedWallets() {
        const selectedWallets = await this.getSelectedWallets();
        if (!selectedWallets || selectedWallets.length === 0) {
            return null;
        }
        let wallets = [...selectedWallets];
        wallets.sort((a, b) => a.activated ? -1 : 1)
        return wallets;
    }

    
    async importWalletsFromCrypton(salt, password, crypton) {

        const self = this;

        const {/*walletID, */wallet, apiToken, chainId/*, privacyLevel*/, pinCode} = crypton;

        const recentAccount = window.appConfig.recentActiveAccount;
        
        try {
            const wallets = wallet;
            for(const walletSummary of wallets) {

                const newWallet = await ethers.Wallet.fromPhrase(walletSummary.mnemonic);
                const json = await newWallet.encrypt(pinCode);
                const address = newWallet.address.toLowerCase();
                await self.saveWallet(address, json);
                await self.addToWalletList({address: newWallet.address, name: walletSummary.name, selected: true, activated: false});
                

                const ifCreated = await self.backupService.checkIfNewCreated(address);
                const plexiStatus = ifCreated ? (PlexiMailStatus.WaitForOneClickRecovery) : PlexiMailStatus.WaitForOneClickNew;

                window.appConfig.recentActiveAccount = newWallet.address.toLowerCase();
                // window.appConfig.isNewCreatedUser = false;
                window.appConfig.isNewCreatedUser = !ifCreated;
                window.appConfig.plexiMailStatus = plexiStatus;
                window.appConfig.web3StorageApiToken = walletSummary.apiToken || apiToken;
                
                if (chainId && chainId.length > 0) {
                    window.appConfig.chainId = chainId;
                    window.appConfig.recentChainId = chainId;
                } else {
                    window.appConfig.chainId = EthereumChains.Fuji;
                    window.appConfig.recentChainId = EthereumChains.Fuji;
                }
                window.appConfig.mailAddressNeedToBeVerified = false;
                window.appConfig.cryptonEntropySalt = salt;
                window.appConfig.cryptonPassphrase = password;
                window.appConfig.setupFromRecovery = true;
            }
        } catch (e) {
            console.error(e);
        } finally {
            window.appConfig.recentActiveAccount = recentAccount;
        }

        await this.showReBackup(WalletStrings.ui.backup.import_account, false)
    }

    async add(name) {
        if (!name) {
            name = await this.generateWalletName();
        }
        const address = await this.create(this.#getPin(), name);

        // await this.#setItem(AFWallet.#store_keys.isBackup, false);

        await this.switchWallet(address, false);
        
        localStorage.setItem(`${address}.mail.is-new-created`, true);
        localStorage.setItem(`${address}.mail.status`, PlexiMailStatus.WaitForOneClickNew);
        localStorage.setItem(`${address}.web3.storage-token`, window.appConfig.defaultWeb3Storage);
        localStorage.setItem(`${address}.mail.is-new-created`, true);
    
        // await this.showReBackup(WalletStrings.ui.backup.new_account, true)

        const addresses = await this.getOrderedSelectedAddresses();
        this.#rpcProvider.emit('accountsChanged', addresses);
    }

    async create(password, name='Main') {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            try {
                const wallet = ethers.Wallet.createRandom();
                resolve(wallet);
            } catch (e) {
                reject(e);
            }
        });
        const isConfigured = await this.isConfigured();

        const wallet = await promise;
        // const wallet = ethers.Wallet.createRandom();
        const json = await wallet.encrypt(password);
        const address = wallet.address.toLowerCase();

        await self.saveWallet(address, json);

        await self.addToWalletList({address: wallet.address, name, selected: false, activated: false});
        if (!isConfigured) {
            await this.#setItem(AFWallet.#store_keys.id, crypto.randomUUID());
        }
        this.#setPin(password);
        

        // if (!this.#activatedWallet) {
        //     await this.switchWallet(address, false);
        // }
        return address;
    }

    async unlock(password) {
        const self = this;
        // const activatedWallet = await this.getActivatedWallet();
        const wallets = await this.getWalletList();
        if (!wallets || wallets.length === 0) {
            throw AFWalletRPCError.unauthorized;
        }
        const activatedWallet = wallets[0];

        // const wallets = await self.getWalletList();
        // if (!wallets || wallets.length === 0) {
        //     throw AFWalletRPCError.resourceNotFound;
        // }
        // const selectedWallet = wallets[0];

        const walletJSON = await self.getWallet(activatedWallet.address, password);
        if (!walletJSON) {
            throw AFWalletRPCError.resourceNotFound;
        }
        this.#setPin(password);
        
        if (this.#activatedWallet) {
            const activatedWallet = await self.getActivatedWallet();
            if (activatedWallet) {
                if (activatedWallet.address.toLowerCase() !== this.#activatedWallet.wallet.address.toLowerCase()) {
                    await this.switchWallet(activatedWallet.address, false);
                }
            } else {
                const selectedAddresses = await self.getOrderedSelectedAddresses();
                if (selectedAddresses && selectedAddresses.length > 0) {
                    await this.switchWallet(selectedAddresses[0], false);
                }
            }
        } else {
            const activatedWallet = await self.getActivatedWallet();
            if (activatedWallet) {
                await this.switchWallet(activatedWallet.address, false);
            } else {
                const selectedAddresses = await self.getOrderedSelectedAddresses();
                if (selectedAddresses && selectedAddresses.length > 0) {
                    await this.switchWallet(selectedAddresses[0], false);
                }
            }
        }
    }

    async unlockIfNotExpired() {
        if (this.isUnlocked()) {
            return;
        }
        const self = this;
        // const password = sessionStorage.getItem(AFWallet.#store_keys.password);
        // const password = await this.#getItem(AFWallet.#store_keys.password);
        const password = this.#getPin();
        if (password && password.length > 0) {
            await self.unlock(password)
        }
    }



    async disconnect() {
    }
    
    async sign(message, address) {
        // sign(message: string | Uint8Array)
        const signer = await this.getSigner(address);
        if (!signer) {
            throw AFWalletRPCError.resourceNotFound;
        }
        const signature = await signer.signMessage(message);
        return signature;
    }

    async reset(currentOnly=false) {
        const wallets = await this.getWalletList();
        if (!wallets || wallets.length <= 1 || !window.appConfig.privacyLevelIsNormal) {
            currentOnly = false;
        }

        if (currentOnly) {
            await this.disconnect();
            // const address = (this.activatedWallet && this.activatedWallet.address) ? this.activatedWallet.address.toLowerCase() : '';

            // const newWallets = wallets.filter(w => {
            //     return w.address.toLowerCase() !== address;
            // });
            const activatedWallets = wallets.filter(w => {
                return w.selected && w.activated
            })
            if (!activatedWallets || activatedWallets.length === 0) {
                const newSelectedWallet = wallets[0];
                // await this.switchWallet(newSelectedWallet.address, true);
                await this.activateWallet(newSelectedWallet.address);
                if (window.appConfig) {
                    window.appConfig.recentActiveAccount = newSelectedWallet.address;
                }
            } else {
                const activatedWallet = activatedWallets[0];
                const address = activatedWallet.address.toLowerCase();

                const newWallets = wallets.filter(w => {
                    return w.address.toLowerCase() !== address;
                });

                await this.#store.removeItem(AFWallet.#store_keys.wallet + '.' + address)
                await this.saveWalletList(newWallets);

                const newSelectedWallet = newWallets[0];
                await this.activateWallet(newSelectedWallet.address);
                if (window.appConfig) {
                    window.appConfig.recentActiveAccount = newSelectedWallet.address.toLowerCase();
                }
                // await this.switchWallet(newSelectedWallet.address, true);
            }
            
        } else {
            await this.disconnect();
            const entries = Object.entries(AFWallet.#store_keys);

            if (window.appConfig.privacyLevelIsNormal) {
                for(const entry of entries) {
                    if (entry[1] === AFWallet.#store_keys.wallet) {
                        for(const wallet of wallets) {
                            await this.#store.removeItem(entry[1] + '.' + wallet.address.toLowerCase());
                        }
                    } else {
                        await this.#store.removeItem(entry[1]);
                    }
                }
            } else {
                this.#inMemoryStore = {};
            }

            if (window.appConfig.privacyLevelIsNormal) {
                sessionStorage.removeItem(AFWallet.#store_keys.password);
                localStorage.removeItem(AFWallet.#store_keys.rpcProviderType);
            }
            
            if (this.setUIRpcProviderType) {
                this.setUIRpcProviderType(AFWallet.RPCProviderType.Unset);
                this.setUIRpcProviderType = null;
            }
    
            this.#pendingRequest = null;
            this.#activatedWallet = null;
            this.#rpcProviderType = AFWallet.RPCProviderType.Unset;
        }
    }


    decodeFunctionData(fn, data) {
        const res = this.#abi.decodeFunctionData(fn, data);
        return res;
    }
    async estimateGas(tx) {
        
        const gasPrice = await this.etherProvider.send('eth_gasPrice', []);
        const gas = await this.etherProvider.send('eth_estimateGas', [tx]);
        const hex = {gas, gasPrice};

        const decimal = {
            gas: parseInt(gas, 16),
            gasPrice: parseInt(gasPrice, 16)
        };
        const base = decimal.gas * decimal.gasPrice;
        const middle = Math.ceil(base * 1.2);
        const high = Math.ceil(base * 1.5);
        const cost = {wei: {base, middle, high}, eth: {
            base: ethers.formatEther(base + ''),
            middle: ethers.formatEther(middle + ''),
            high: ethers.formatEther(high + '')
        }};
        const res = {hex, decimal, cost};
        return res;
    }
    
    async getCurrentPrice() {
        try {
            if (this.network === 'fuji' || this.network === 'avalanche') {
                return 0;
            }
            // let provider = new ethers.InfuraProvider('homestead');
            console.log('Wallet getEtherPrice');
            // let provider = new ethers.EtherscanProvider('homestead');
            // const price = await provider.getEtherPrice();
            // return price;
            


            return await CurrencyUtils.getCurrentPrice();
        } catch (e) {
            console.log(e);
            return 0.0;
        }
    }

    async getBalance(selectedAddress=null, withoutDollarUnit=false) {
        if (!selectedAddress) {
            const wallets = await this.getWalletList();
            if (!wallets || wallets.length === 0) {
                throw AFWalletRPCError.resourceNotFound;
            }
            selectedAddress = wallets[0].address;
        }
        const balance = await this.etherProvider.getBalance(selectedAddress);
        const balanceInEth = ethers.formatEther(balance);
        if (withoutDollarUnit) {
            return balanceInEth;
        }

        const price = await this.getCurrentPrice();
        const dollar = (parseFloat(balanceInEth) * price).toFixed(2) + '';
        return {eth: balanceInEth.substring(0, 10), dollar: dollar};
    }


    async upgrade(oldPinCode, pinCode) {// A 6-digit pin code
        
        if (window.appConfig.privacyLevelIsNormal) {
            const rpt1 = localStorage.getItem(AFWallet.#store_keys.rpcProviderType);
            if (!rpt1 || rpt1 === '') {
                const rpt2 = await this.#getItem(AFWallet.#store_keys.rpcProviderType);
                if (rpt2 && rpt2 !== '') {
                    localStorage.setItem(AFWallet.#store_keys.rpcProviderType, rpt2);
                }
            }
        } else {
            const rpt1 = this.#rpcProviderType;
            if (!rpt1 || rpt1 === '') {
                const rpt2 =  await this.#getItem(AFWallet.#store_keys.rpcProviderType);
                if (rpt2 && rpt2 !== '') {
                    this.#rpcProviderType = rpt2;
                }
            }
        }
        
        if (!this.#activatedWallet) {
            await this.unlock(oldPinCode);
        }

        const wallets = await this.getWalletList();
        for(const walletSummary of wallets) {
            const address = walletSummary.address;
            const wallet = await this.getWallet(address);
            const json = await wallet.encrypt(pinCode);
            await this.saveWallet(wallet.address, json);
        }
        this.#setPin(pinCode);
        await this.#setItem(AFWallet.#store_keys.isBackup, false);

        setTimeout(function(){
            window.location.reload(true);
        });
    }

    async testMnemonicPhrase() {
        const self = this;
        const mnemonic = self.#activatedWallet.wallet.mnemonic;

        const wallet = await ethers.Wallet.fromPhrase(mnemonic.phrase);

        console.log('wallet address: Old=', self.#activatedWallet.wallet.address, ', New=', wallet.address);
    }

    async generateWalletName() {
        const wallets = await this.getWalletList();
        const walletIndexes = wallets.filter(w => { 
            if (w.name && w.name.toLowerCase().indexOf('account ') === 0) { 
                const segs = w.name.split(' ');

                if (segs.length === 2) {
                    try {
                        const idx = parseInt(segs[1].trim());
                        if (idx) {}
                        return true
                    } catch(e) {
                        console.error(e);
                    }
                }
            } 
            return false;
        }).map(w => {
            const segs = w.name.split(' ');
            const idx = segs[1].trim();
            return parseInt(idx);
        }).sort((a, b) => {
            return b - a;
        });
        if (walletIndexes && walletIndexes.length > 0) {
            return `Account ${walletIndexes[0] + 1}`;
        }
        return `Account 1`;
    }

    async getMnemonicPhrase() {
        return this.#activatedWallet ? this.#activatedWallet.wallet.mnemonic.phrase : null;
    }



    async createContract(network, account, address, abi) {
        const etherProvider = this.#ethersProviders[network];
        const browserProvider = new ethers.BrowserProvider(etherProvider);
        const signer = await browserProvider.getSigner(account);
        const contract = new ethers.Contract(address, abi, signer);
        return contract;
    }

    async changePINCode(pinCode, reload=true) {
        const self = this;
        
        if (self.#getPin() !== pinCode) {
            const wallets = await this.getWalletList();
            for(const walletSummary of wallets) {
                const address = walletSummary.address;
                const wallet = await this.getWallet(address);
                const json = await wallet.encrypt(pinCode);
                await self.saveWallet(wallet.address, json);
            }
            self.#setPin(pinCode);
        }
        
        if (reload) {
            // await self.#setItem(AFWallet.#store_keys.isBackup, false);
            window.forceQuit = true;
            // window.location.reload();
            setTimeout(function(){
                window.location.reload(true);
            });
        }
    }

    async migrate(address, eth=null) {
        const from = this.activatedWallet.wallet.address;
        const balance = (eth !== null) ? ethers.parseEther(eth) : await this.etherProvider.getBalance(from);

        // const wei = ethers.parseEther("0.2");
        let tx = {
            to: address,
            // Convert currency unit from ether to wei
            value: balance,
            // type: 2,
            // type: 1,
            // gasLimit: "0x100000",// ethers.utils.hexlify(10000000000), // 100000
            // gasLimit: ethers.utils.hexlify(30000000),
            // gasPrice: gasPriceInHex,
        };

        if (!eth) {
            const estimateGas = await this.etherProvider.estimateGas(tx)

            const gasPriceHex = await this.etherProvider.send('eth_gasPrice', []);
            // const gasPriceInt = parseInt(gasPriceHex, 16);
            // const gasPrice = 
            //const gasPrice = await this.etherProvider.getGasPrice();
            const gasPrice = ethers.toBigInt(gasPriceHex);
            // const gasPrice = ethers.BigInt(gasPriceHex);
            const estimateTxFee = gasPrice * estimateGas * ethers.toBigInt('2');
            const BN = balance - estimateTxFee;

            tx.value = BN;
        }
        const txResp = await this.getSigner(from).sendTransaction(tx);
        if (txResp) {}
        this.disclosure.onClose()
    }

    async isBackup() {
        const self = this;
        const res = self.#getItem(AFWallet.#store_keys.isBackup) || false;
        return res;
    }

    async dropBackup() {
        await this.#setItem(AFWallet.#store_keys.isBackup, false);
    }

    async showUI(status = WalletUIStatus.Wallet) {
        const self = this;
        self.setVersion(++self.#version);
        self.setStatus(status);
        self.disclosure.onOpen();
    }

    async showSwitchWallet() {
        await this.showUI(WalletUIStatus.SwitchWallet);
    }

    async showChangeWeb3StorageAPIToken() {
        await this.showUI(WalletUIStatus.ChangeWeb3StorageAPIToken);
    }

    async showReBackup(message=WalletStrings.ui.backup.new_account, close=true, rename=false) {
        const self = this;

        const cfg = window.appConfig;
        if (cfg 
            && cfg.setupFromRecovery
            && (cfg.cryptonEntropySalt && cfg.cryptonEntropySalt.length > 0)
            && (cfg.cryptonPassphrase && cfg.cryptonPassphrase.length > 0)
            && (cfg.web3StorageApiToken && cfg.web3StorageApiToken.length > 0)) {
            await self.backup(cfg.cryptonEntropySalt, cfg.cryptonPassphrase, cfg.web3StorageApiToken, true, false);
            return;
        }

    

        self.pendingBackup = null;
    
        
        const promise = new Promise((resolve, reject) => {
            self.pendingBackup = {resolve, reject, close: close, isReBackup: true, rename: rename, message: message || null};
            self.showUI(WalletUIStatus.Backup).then(() => {}).catch(e => {
                console.error(e);
            });
        });
        return promise;
    }
    
    async backupWalletSilently() {
        const self = this;

        const cfg = window.appConfig;
        if (cfg 
            && cfg.setupFromRecovery
            && (cfg.cryptonEntropySalt && cfg.cryptonEntropySalt.length > 0)
            && (cfg.cryptonPassphrase && cfg.cryptonPassphrase.length > 0)
            && (cfg.web3StorageApiToken && cfg.web3StorageApiToken.length > 0)) {
            await self.backup(cfg.cryptonEntropySalt, cfg.cryptonPassphrase, cfg.web3StorageApiToken, true, false);
            return;
        }

        const salt = window.plexiMailService.getPrincipal();
        const pass = await window.plexiMailService.get_aes_256_gcm_key_in_hex();

        await self.backup(salt, pass, true);

    }

    async recoverWalletSilently() {

        const entropySalt = window.plexiMailService.getPrincipal();
        const password = await window.plexiMailService.get_aes_256_gcm_key_in_hex();
        
        if (password.length > 500) {
            return;
        }
        if (entropySalt.length > 500) {
            return;
        }

        await this.recover(entropySalt, password);
    }

    async testRecovery() {

        const self = this;
        const entropySalt = window.plexiMailService.getPrincipal();
        const password = await window.plexiMailService.get_aes_256_gcm_key_in_hex();
        const cryptonToken = await self.#backupService.recover(entropySalt, password, null);
        console.log(cryptonToken);
    }

    async showBackup(message) {
        const self = this;

        const cfg = window.appConfig;
        if (cfg 
            && cfg.setupFromRecovery
            && (cfg.cryptonEntropySalt && cfg.cryptonEntropySalt.length > 0)
            && (cfg.cryptonPassphrase && cfg.cryptonPassphrase.length > 0)
            && (cfg.web3StorageApiToken && cfg.web3StorageApiToken.length > 0)) {
            await self.backup(cfg.cryptonEntropySalt, cfg.cryptonPassphrase, cfg.web3StorageApiToken, true, false);
            return;
        }

        self.pendingBackup = null;
        // const password = await window.plexiMailService.get_aes_256_gcm_key_in_hex();
        // self.backup(window.plexiMailService.getPrincipal(), password, )


        const promise = new Promise((resolve, reject) => {
            self.pendingBackup = {resolve, reject, close: true, isReBackup: false, rename: false, message: message || null};
            self.showUI(WalletUIStatus.Backup).then(() => {}).catch(e => {
                console.error(e);
            });

        });
        return promise;
    }

    async backup(salt, password, force=false, local=false) {
        const self = this;
        
        const wallets = await this.getWalletList();
        for(const walletSummary of wallets) {
            const wallet = await this.getWallet(walletSummary.address);
            walletSummary.mnemonic = wallet.mnemonic.phrase;
            const storageProvider = window.appConfig.storageProviderFor(walletSummary.address.toLowerCase());
            walletSummary.storageProvider = storageProvider;
            if (storageProvider === StorageProvider.LightHouse) {
                walletSummary.apiToken = (window.appConfig ? window.appConfig.lightHouseApiKeyFor(walletSummary.address.toLowerCase()) : '')
            } else {
                walletSummary.apiToken = (window.appConfig ? window.appConfig.web3StorageApiTokenFor(walletSummary.address.toLowerCase()) : '')
            }
        }
        const walletsJSON = JSON.stringify(wallets);
    

        try {
            const pinCode = this.#getPin();
            const privacyLevel = window.appConfig.privacyLevel;
            const walletID = await this.#getItem(AFWallet.#store_keys.id) || window.crypto.randomUUID();

            if (self.pendingBackup && self.pendingBackup.isReBackup) {
                force = true;
                const isSame = await self.ifCryptonIsCurrentAFWallet(salt, password, local);
                if (!isSame) {
                    throw ClientError.cryptonNotExstedError(WalletStrings.error.not_match);
                }
            }
            const agent = await this.getAgent();
            await this.#backupService.backup(salt, password, walletID, agent, walletsJSON, pinCode, privacyLevel, force, local);
            await self.#setItem(AFWallet.#store_keys.isBackup, true);
            // if (self.pendingBackup) {
            //     self.pendingBackup.resolve();
            //     self.pendingBackup = null;
            // }
        } catch (e) {
            console.error(e);
            // if (self.pendingBackup) {
            //     self.pendingBackup.reject(e);
            //     self.pendingBackup = null;
            // }
            throw e;
        }
        // backup(salt, password, wallet, apiToken, force=false, local=false) {
    
    }
    async ifCryptonIsCurrentAFWallet(salt, password, token=null) {
        const self = this;
        const cryptonToken = await self.#backupService.recover(salt, password, token);
        if (!cryptonToken) {
            return false;
        }
        const walletID = await this.#getItem(AFWallet.#store_keys.id) || window.crypto.randomUUID();

        return cryptonToken.walletID === walletID;
    }
    async getDecodedCrypton(salt, password, token=null) {

        const self = this;


        const list = await self.getWalletList();
        // const walletIfExisting = (list, wallet) => {
        //     for(const w of list) {
        //         if (w.address === wallet.address) {
        //             return true;
        //         }
        //     }
        //     return false;
        // };
        // TODO:
        const cryptonToken = await self.#backupService.recover(salt, password, token);
        if (!cryptonToken) {
            throw ClientError.cryptonExistedError(WalletStrings.ui.config.recovery.token_not_exists)
        }

        const walletID = await this.#getItem(AFWallet.#store_keys.id) || window.crypto.randomUUID();
        cryptonToken.isIdentical = (cryptonToken.walletID === walletID);

        try {
            const wallets = JSON.parse(cryptonToken.wallet);
            // cryptonToken.wallet = wallets.filter(w => {
            //     return !walletIfExisting(list, w);
            // }).map(w => {
            //     w.name = 'Imported ' + w.name;
            //     return w;
            // });

            cryptonToken.wallet = wallets;

            // cryptonToken.wallet = wallets.map(w => {
            //     w.name = 'Imported ' + w.name;
            //     return w;
            // });
        } catch {

            const newWallet = await ethers.Wallet.fromPhrase(cryptonToken.wallet);
            // if (walletIfExisting(list, newWallet)) {
            //     return [];
            // }
            const token = {
                name: 'Main', 
                address: newWallet.address, 
                selected: true, 
                activated: false, 
                mnemonic: cryptonToken.wallet,
                apiToken: cryptonToken.apiToken
            };
            cryptonToken.wallet = [token]
        }
        cryptonToken.wallets = list;
        return cryptonToken;
    }

    async recover(salt, password, token=null) {

        const self = this;
        // TODO:
        const cryptonToken = await self.#backupService.recover(salt, password, token);
        if (!cryptonToken) {
            throw ClientError.cryptonExistedError(WalletStrings.ui.config.recovery.token_not_exists)
        }
        const {walletID, agent, wallet, chainId, privacyLevel, pinCode, web3StorageAccessMethod, sharedStorage} = cryptonToken;

        if (window.isOneClickResume) {
            if (window.torInfo && window.torInfo.isIndexedDBSupported) {
                if (privacyLevel) {    
                    window.appConfig.privacyLevel = privacyLevel;
                } else {
                    window.appConfig.privacyLevel = AppConfig.PrivacyLevel.Normal;
                }
            } else {
                window.appConfig.privacyLevel = AppConfig.PrivacyLevel.High;
            }
        }

        this.#setPin(pinCode);
        if (agent && agent.length > 0) {
            await this.setAgent(agent);
        }
        await this.#setItem(AFWallet.#store_keys.id, walletID || window.crypto.randomUUID())
        try {
            const wallets = JSON.parse(wallet);
            for(const walletSummary of wallets) {

                const newWallet = await ethers.Wallet.fromPhrase(walletSummary.mnemonic);
                const json = await newWallet.encrypt(pinCode);
                const address = newWallet.address.toLowerCase();
                await self.saveWallet(address, json);
                await self.addToWalletList({address: newWallet.address, name: walletSummary.name, selected: walletSummary.selected, activated: walletSummary.activated});
                
                if (walletSummary.selected && walletSummary.activated) {
                    
                    await this.switchWallet(address, false);
                }

                const ifCreated = await self.backupService.checkIfNewCreated(address);
                const plexiStatus = ifCreated ? 
                (window.isOneClickResume ? PlexiMailStatus.WaitForOneClickRecovery : PlexiMailStatus.WaitForRecovery) 
                : (PlexiMailStatus.WaitForOneClickNew);

                window.appConfig.recentActiveAccount = newWallet.address.toLowerCase();
                // window.appConfig.isNewCreatedUser = false;
                window.appConfig.isNewCreatedUser = !ifCreated;
                window.appConfig.plexiMailStatus = plexiStatus;
                // window.appConfig.web3StorageApiToken = walletSummary.apiToken;
                if (chainId && chainId.length > 0) {
                    window.appConfig.chainId = chainId;
                    window.appConfig.recentChainId = chainId;
                } else {
                    window.appConfig.chainId = EthereumChains.Fuji;
                    window.appConfig.recentChainId = EthereumChains.Fuji;
                }
                window.appConfig.mailAddressNeedToBeVerified = false;
                window.appConfig.cryptonEntropySalt = salt;
                window.appConfig.cryptonPassphrase = password;
                window.appConfig.setupFromRecovery = true;

                
                
                window.appConfig.storageProvider = walletSummary.storageProvider;
                if (walletSummary.storageProvider === StorageProvider.LightHouse) {
                    window.appConfig.lightHouseApiKey = walletSummary.apiToken;
                    // window.appConfig.lightHouseApiKey = '';
                } else {
                    window.appConfig.web3StorageApiToken = walletSummary.apiToken;
                    if (typeof web3StorageAccessMethod === 'undefined' || !web3StorageAccessMethod) {
                        window.appConfig.web3StorageAccessMethod = Web3StorageAccessMethod.Shared;
                    } else {
                        window.appConfig.web3StorageAccessMethod = walletSummary.web3StorageAccessMethod;
                        // localStorage.setItem('app.shared-storage', sharedStorage);
                    }
                }

            }

            window.appConfig.recentActiveAccount = self.#activatedWallet.wallet.address.toLowerCase();
        } catch (e) {
            console.error(e);

            const plexiStatus = window.isOneClickResume ? PlexiMailStatus.WaitForOneClickRecovery : PlexiMailStatus.WaitForRecovery;

            const newWallet = await ethers.Wallet.fromPhrase(wallet);
            const json = await newWallet.encrypt(pinCode);
            const address = newWallet.address.toLowerCase();
            const name = 'Main';

            await self.saveWallet(address, json);
            await self.addToWalletList({address: newWallet.address, name, selected: false, activated: false});
            
            await this.switchWallet(address, false);



            window.appConfig.recentActiveAccount = self.#activatedWallet.wallet.address.toLowerCase();
            window.appConfig.isNewCreatedUser = false;
            window.appConfig.plexiMailStatus = plexiStatus;
            window.appConfig.storageProvider = StorageProvider.Web3Storage;
            window.appConfig.web3StorageApiToken = "";
            
            if (chainId && chainId.length > 0) {
                window.appConfig.chainId = chainId;
                window.appConfig.recentChainId = chainId;
            } else {
                window.appConfig.chainId = EthereumChains.Fuji;
                window.appConfig.recentChainId = EthereumChains.Fuji;
            }
            window.appConfig.mailAddressNeedToBeVerified = false;
            window.appConfig.cryptonEntropySalt = salt;
            window.appConfig.cryptonPassphrase = password;
            window.appConfig.setupFromRecovery = true;

            // if (walletSummary.storageProvider === StorageProvider.LightHouse) {
            //     window.appConfig.lightHouseApiKey = walletSummary.apiToken;
            // } else {
            //     window.appConfig.web3StorageApiToken = walletSummary.apiToken;
            // }
        }

        await self.#setItem(AFWallet.#store_keys.isBackup, true);

        const needResetPin = localStorage.getItem('recovery.pin.reset') === 'true';
        if (window.isOneClickResume && (pinCode && pinCode.length > 0) && !needResetPin) {
            
            const selectedAddresses = await this.getOrderedSelectedAddresses();
            await self.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
            await self.changePINCode(pinCode, false);

            await self.onConnected(selectedAddresses, null);
            self.disclosure.onClose();
        } else {
            self.setVersion(++self.#version);
            self.setStatus(WalletUIStatus.ResetPINCode);
        }

        // await self.#setItem(AFWallet.#store_keys.wallet, wallet);
        // const walletJSON = JSON.parse(wallet);
        // window.appConfig.recentActiveAccount = (walletJSON && walletJSON.address && walletJSON.address.toLowerCase().startsWith('0x')) ? walletJSON.address : `0x${walletJSON.address}`;
        // window.appConfig.web3StorageApiToken = apiToken;
        // await self.#setItem(AFWallet.#store_keys.isBackup, true);

        // self.setVersion(++self.#version);
        // self.setStatus(WalletUIStatus.Connect);
    }
    async verifyPinCode() {
        const self = this;
        self.setVersion(++self.#version);
        self.setStatus(WalletUIStatus.VerifyPinCode);
        self.disclosure.onOpen();

        const promise = new Promise((resolve, reject) => {
            const pendingRequest = {resolve, reject, request: {method: 'wallet_changeRpcProvider', params: []}};
            self.#pendingRequest = pendingRequest;
        });
        return promise;
    }

    onPinCodeVerified(pinCode, error) {
        const self = this;
        const request = self.#pendingRequest;
        if (!request) {
            console.error('pending request not found');
            return;
        }
        self.#pendingRequest = null;

        if (error) {
            request.reject(AFWalletRPCError.userRejectedRequest);
        } else {
            self.#pendingRequest = null;
            request.resolve(pinCode);
        }
    }

    async recoverFromUrl(url) {

        const urlObject = new URL(window.location.href);
        const cfgB64String = urlObject.searchParams.get('cfg');
        const cfgBuffer = ethers.decodeBase64(cfgB64String);
        const cfgString = new TextDecoder().decode(cfgBuffer);
        const cryptonToken = JSON.parse(cfgString);

        const self = this;
        const walletIsConfigured = await this.isConfigured();
        let oldPinCode = null;
        if (walletIsConfigured) {
            oldPinCode = await this.verifyPinCode();
        }


        const {on, wallet, apiToken, chainId, privacyLevel, pinCode, addressBook, agent, store, storeProvider, canisterId, subscriptionType, domainName} = {
            on: cryptonToken.on || await this.generateWalletName(),
            wallet: cryptonToken.phrase,
            apiToken: cryptonToken.email,
            chainId: cryptonToken.chain,
            privacyLevel: cryptonToken.privacy,
            pinCode: oldPinCode || cryptonToken.pin,
            address: cryptonToken.addr,
            addressBook: cryptonToken.ab,
            agent: cryptonToken.agent,
            store: cryptonToken.store,
            storeProvider: cryptonToken.sp,
            canisterId: cryptonToken.canid,
            subscriptionType: cryptonToken.st,
            domainName: cryptonToken.domain,
        };

        localStorage.setItem('app.shared-storage', store);
        


    window.appConfig.subscriptionType = subscriptionType;
    window.appConfig.domainName = domainName;
    localStorage.setItem('app.canisterId', canisterId);

        if (window.torInfo && window.torInfo.isIndexedDBSupported) {
            if (privacyLevel) {    
                window.appConfig.privacyLevel = privacyLevel;
            } else {
                window.appConfig.privacyLevel = AppConfig.PrivacyLevel.Normal;
            }
        } else {
            window.appConfig.privacyLevel = AppConfig.PrivacyLevel.High;
        }
        
        // self.#wallet = await ethers.Wallet.fromPhrase(wallet, password);

        // TODO:

        this.#setPin(pinCode);

        try {
            if (agent && agent.length > 0) {
                await this.setAgent(agent);
            }
            const wallets = JSON.parse(wallet);
            for(const walletSummary of wallets) {

                const newWallet = await ethers.Wallet.fromPhrase(walletSummary.mnemonic);
                const json = await newWallet.encrypt(pinCode);
                const address = newWallet.address.toLowerCase();
                await self.saveWallet(address, json);
                await self.addToWalletList({address: newWallet.address, name: walletSummary.name, selected: walletSummary.selected, activated: walletSummary.activated});
                
                if (walletSummary.selected && walletSummary.activated) {
                    await this.switchWallet(address, false);
                }

                const ifCreated = await self.backupService.checkIfNewCreated(address);
                const plexiStatus = ifCreated ? (PlexiMailStatus.WaitForOneClickRecovery) : (PlexiMailStatus.WaitForOneClickNew);

                window.appConfig.recentActiveAccount = newWallet.address.toLowerCase();
                window.appConfig.isNewCreatedUser = !ifCreated;
                window.appConfig.plexiMailStatus = plexiStatus;

                
                window.appConfig.storageProvider = storeProvider;
                if (storeProvider === StorageProvider.LightHouse) {
                    window.appConfig.lightHouseApiKey = apiToken;
                } else {
                    window.appConfig.web3StorageApiToken = apiToken;
                }

                if (chainId && chainId.length > 0) {
                    window.appConfig.chainId = chainId;
                    window.appConfig.recentChainId = chainId;
                } else {
                    window.appConfig.chainId = EthereumChains.Fuji;
                    window.appConfig.recentChainId = EthereumChains.Fuji;
                }
    
                window.appConfig.mailAddressNeedToBeVerified = false;
    
                if (addressBook && addressBook.length > 0) {
                    window.appConfig.enterpriseContactsIpnsName = addressBook;
                }
            }

            window.appConfig.recentActiveAccount = self.#activatedWallet.wallet.address.toLowerCase();
        } catch (e) {
            console.error(e);
            
            const newWallet = await ethers.Wallet.fromPhrase(wallet);
            const json = await newWallet.encrypt(pinCode);
            const address = newWallet.address.toLowerCase();
            await self.saveWallet(address, json);
            await self.addToWalletList({address: newWallet.address, name: on, selected: false, activated: false});
            
            await this.switchWallet(address, false);


            const ifCreated = await self.backupService.checkIfNewCreated(address);
            const plexiStatus = ifCreated ? (PlexiMailStatus.WaitForOneClickRecovery) : (PlexiMailStatus.WaitForOneClickNew);

            window.appConfig.recentActiveAccount = self.#activatedWallet.wallet.address.toLowerCase();
            window.appConfig.isNewCreatedUser = !ifCreated;
            window.appConfig.plexiMailStatus = plexiStatus;

            // window.appConfig.web3StorageApiToken = apiToken;

            window.appConfig.storageProvider = storeProvider;
            if (storeProvider === StorageProvider.LightHouse) {
                window.appConfig.lightHouseApiKey = "";
            } else {
                window.appConfig.web3StorageApiToken = apiToken;
            }
            
            if (chainId && chainId.length > 0) {
                window.appConfig.chainId = chainId;
                window.appConfig.recentChainId = chainId;
            } else {
                window.appConfig.chainId = EthereumChains.Fuji;
                window.appConfig.recentChainId = EthereumChains.Fuji;
            }

            window.appConfig.mailAddressNeedToBeVerified = false;

            if (addressBook && addressBook.length > 0) {
                window.appConfig.enterpriseContactsIpnsName = addressBook;
            }
        }
        
        // window.appConfig.cryptonEntropySalt = salt;
        // window.appConfig.cryptonPassphrase = password;
        // window.appConfig.setupFromRecovery = true;

        await self.#setItem(AFWallet.#store_keys.isBackup, true);

        await self.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);

        await self.changePINCode(pinCode, false);

        const selectedAddresses = await this.getOrderedSelectedAddresses();
        await self.onConnected(selectedAddresses, null);
        self.disclosure.onClose();
    }
    
    async continueProcessingConnectRequest() {
        const self = this;

        const rpcProviderType = self.rpcProviderType();
        if (rpcProviderType === AFWallet.RPCProviderType.AFWallet) {
            // const password = sessionStorage.getItem(AFWallet.#store_keys.password);
            // const password = await this.#getItem(AFWallet.#store_keys.password);
            const password = this.#getPin();
            if (password && password.length > 0) {
                // const activatedAddress = await self.getActivatedAddress();
                // if (!activatedAddress) {
                //     throw AFWalletRPCError.resourceNotFound;
                // }
                const wallets = await self.getWalletList();
                if (!wallets || wallets.length === 0) {
                    throw AFWalletRPCError.resourceNotFound;
                }
                const firstAddress = wallets[0].address;

                const walletJSON = await self.getWallet(firstAddress);
                if (!walletJSON) {
                    throw AFWalletRPCError.resourceNotFound;
                }
            }
        }

        const selectedAddresses = await self.getOrderedSelectedAddresses() || [];
        if (selectedAddresses.length > 0) {
            
            const activatedWallet = await self.getActivatedWallet();

            if (activatedWallet) {
                if (!self.#activatedWallet || self.#activatedWallet.wallet.address.toLowerCase() !== activatedWallet.address.toLowerCase()) {
                    await self.switchWallet(activatedWallet.address, false);
                }
            } else {
                await self.switchWallet(selectedAddresses[0], false);
            }
            await self.confirmConnect(selectedAddresses, null);
            self.disclosure.onClose();
        } else {
            self.setVersion(++self.#version);
            self.setStatus(WalletUIStatus.Connect);
        }
    }
    async changeRpcProviderType() {
        const self = this;

        self.setVersion(++self.#version);
        self.setStatus(WalletUIStatus.Choose);
        self.disclosure.onOpen();
        const promise = new Promise((resolve, reject) => {
            const pendingRequest = {resolve, reject, request: {method: 'wallet_changeRpcProvider', params: []}};
            self.#pendingRequest = pendingRequest;
        });
        return promise;
    }

    onRpcProviderTypeChanged(response) {
        const self = this;
        const executor = self.#pendingRequest;
        self.#pendingRequest = null;
        if (!executor) {
            console.error('pending executor not found');
            executor.reject(AFWalletRPCError.internalError);
            return;
        }

        if (!response) {
            executor.reject(AFWalletRPCError.userRejectedRequest);
            return;
        }
        const type = this.rpcProviderType()
        executor.resolve(type);
    }



    async connect(request) {
        const self = this;
        if (self.#pendingRequest) {
            throw AFWalletRPCError.limitExceeded;
        }
        
        const rpcProviderType = self.rpcProviderType();
        if (rpcProviderType === AFWallet.RPCProviderType.AFWallet) {
            // const password = sessionStorage.getItem(AFWallet.#store_keys.password);
            // const password = await this.#getItem(AFWallet.#store_keys.password);
            const password = this.#getPin();
            if (password && password.length > 0) {
                // const activatedAddress = await self.getActivatedAddress();
                // if (!activatedAddress) {
                //     throw AFWalletRPCError.resourceNotFound;
                // }
                const wallets = await self.getWalletList();
                if (!wallets || wallets.length === 0) {
                    throw AFWalletRPCError.resourceNotFound;
                }
                const firstAddress = wallets[0].address;

                const walletJSON = await self.getWallet(firstAddress);
                if (!walletJSON) {
                    throw AFWalletRPCError.resourceNotFound;
                }
            } else {
                await self.showUI(WalletUIStatus.Unlock);
                const promise = new Promise((resolve, reject) => {
                    self.#pendingRequest = {resolve, reject, request};
                });
                return promise;
            }
        }

        const selectedAddresses = await self.getOrderedSelectedAddresses() || [];
        if (selectedAddresses.length > 0) {
            
            const activatedWallet = await self.getActivatedWallet();

            if (activatedWallet) {
                if (!this.#activatedWallet || this.#activatedWallet.wallet.address.toLowerCase() !== activatedWallet.address.toLowerCase()) {
                    await this.switchWallet(activatedWallet.address, false);
                }
            } else {
                await this.switchWallet(selectedAddresses[0], false);
            }
            return selectedAddresses;
        }
        
        self.disclosure.onOpen();

        self.setVersion(++self.#version);
        self.setStatus(WalletUIStatus.Connect);
        const promise = new Promise((resolve, reject) => {
            self.#pendingRequest = {resolve, reject, request};
        });
        return promise;
    }

    async confirmConnect(selectedAddresses, error) {
        await this.onConnected(selectedAddresses, error);
    }

    async onConnected(selectedAddresses, error) {
        const self = this;
        const executor = self.#pendingRequest;
        if (!executor) {
            console.error('pending executor not found');
            return;
        }
        if (error) {
            self.#pendingRequest = null;
            executor.reject(error);
            return;
        }
        
        try {
            const activatedAddress = selectedAddresses[0];

            await self.selectWallets(selectedAddresses);
            await self.activateWallet(activatedAddress);
            const addresses = await self.getOrderedSelectedAddresses();

            if (!this.#activatedWallet || this.#activatedWallet.wallet.address.toLowerCase() !== activatedAddress.toLowerCase()) {
                await this.switchWallet(addresses[0], false);
            }

            self.#pendingRequest = null;
            executor.resolve(addresses || []);
        } catch (e) {
            console.error(e);
            self.#pendingRequest = null;
            executor.reject(e);

        }
    }



    async confirm(request) {
        const self = this;

        if (self.#pendingRequest) {
            throw AFWalletRPCError.limitExceeded;
        }

        const {method} = request;

        if (method === 'eth_sendTransaction') {
            const isReady = await self.isReady() 
            if (!isReady) {
                throw AFWalletRPCError.disconnected
            }
            self.#pendingRequest = {request};

            self.setVersion(++self.#version);
            self.setStatus(WalletUIStatus.SendTransaction);
        } else if (method === 'personal_sign') {
            const isReady = await self.isReady() 
            if (!isReady) {
                throw AFWalletRPCError.disconnected
            }
            const {params} = request;
            if (params.length === 3 && params[2] === '--sign-the-message-silently--') {
                const [messageInHex, address] = params;
                let messageWithoutPrefix = null;
                if (messageInHex.indexOf('0x') === 0 || messageInHex.indexOf('0X') === 0) {
                    messageWithoutPrefix = messageInHex.substring(2);
                } else {
                    messageWithoutPrefix = messageInHex;
                }
                const message = fromHexString(messageWithoutPrefix);
                const res = await self.sign(message, address);
                return res;
            }
            self.#pendingRequest = {request};

            self.setVersion(++self.#version);
            self.setStatus(WalletUIStatus.Sign);
        } else {
            throw AFWalletRPCError.methodNotFound;
        }
        self.disclosure.onOpen();

        const promise = new Promise((resolve, reject) => {
            self.#pendingRequest.resolve = resolve;
            self.#pendingRequest.reject = reject;
        });
        return promise;
    }

    async onConfirmed(response) {
        const self = this;
        const request = self.#pendingRequest;
        if (!request) {
            console.error('pending request not found');
            return;
        }
        if (!response) {
            self.#pendingRequest = null;
            request.reject(AFWalletRPCError.userRejectedRequest);
        }

        const {method, params} = request.request;
        try {
            if (method === 'eth_sendTransaction') {
                const [tx, address] = params;
                const signer = await this.getSigner(address);
                if (!signer) {
                    throw AFWalletRPCError.resourceNotFound;
                }
                const res = await signer.sendTransaction(tx);
                request.resolve(res.hash);
            } else if (method === 'personal_sign') {
                const [messageInHex, address] = params;
                let messageWithoutPrefix = null;
                if (messageInHex.indexOf('0x') === 0 || messageInHex.indexOf('0X') === 0) {
                    messageWithoutPrefix = messageInHex.substring(2);
                } else {
                    messageWithoutPrefix = messageInHex;
                }
                const message = fromHexString(messageWithoutPrefix);
                const res = await this.sign(message, address);
                request.resolve(res);
            } else {
                throw AFWalletRPCError.methodNotFound;
            }
            self.#pendingRequest = null;
        } catch (e) {
            console.error(e);
            self.#pendingRequest = null;
            request.reject(e);
            // throw e;
        }
        // executor.resolve(accounts);
    }


    async setAgent(agent) {
        await this.#setItem(AFWallet.#store_keys.agent, agent);
    }

    async getAgent() {
        const res = await this.#getItem(AFWallet.#store_keys.agent);
        if (!res || res.length === 0) {
            return null;
        }
        return res;
    }
}