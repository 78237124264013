// import { ClientError } from "../../common/errors";

import { create } from "@web3-storage/w3up-client";
import * as Delegation from "@web3-storage/w3up-client/delegation";

// import * as Signer from "@ucanto/principal/ed25519"; // Agents on Node should use Ed25519 keys

// import { StoreIndexedDB } from "@web3-storage/access/stores/store-indexeddb";
import W3upStoreIndexedDB from "./W3upStoreIndexedDB";
// import { AgentData } from '@web3-storage/access/agent'
import { CID } from "multiformats/cid";
import * as CAR from "@ucanto/transport/car";
import * as DID from "@ipld/dag-ucan/did";

import {
  Store as StoreCapabilities,
  Upload as UploadCapabilities,
} from "@web3-storage/capabilities";
import { Upload } from "@web3-storage/upload-client";
import { uploadFile, uploadDirectory } from "@web3-storage/upload-client";
import { USE_DAG_FILE } from "../../common/constants";
import { BaseWeb3Storage } from "./BaseWeb3Storage";
import W3upStoreInMemory from "./W3upStoreInMemory";
// import { extract } from '@ucanto/core/delegation';
import { decodeBase64, encodeBase64 } from "ethers";
import * as Signer from "@ucanto/principal/ed25519";
// import { keys } from 'libp2p-crypto';
import * as W3Account from "@web3-storage/w3up-client/account";
// import * as ucanto from '@ucanto/core'
import cryptoRandomString from 'crypto-random-string'

import { importDAG } from "@ucanto/core/delegation";
import { CarWriter } from "@ipld/car/writer";
import { CarReader } from "@ipld/car/reader";
import { StorageProvider, Web3StorageAccessMethod } from "../../utils/Types";
import { ServerError } from "../../common/errors";

// import { delegate} from '@ucanto/core';

class W3UpWeb3Storage extends BaseWeb3Storage {
  #client = null;
  #store = null;
  constructor() {
    super();
    this.#client = null;
    this.#store = null;
  }

  get type() {
    return StorageProvider.Web3Storage;
  }

  account(email) {
    if (!this.#client) {
      return null;
    }
    if (!email) {
      email = localStorage.getItem("w3up-account-email");
    }
    if (!email) {
      return null;
    }

    const accounts = Object.values(W3Account.list(this.#client));
    return accounts.find((account) => account.toEmail() === email);
  }



  #asCarLink(cid) {
    if (cid.version === 1 && cid.code === CAR.codec.code) {
      return /** @type {CARLink} */ (cid);
    }
  }

  #parseCarLink(cidStr) {
    try {
      return this.#asCarLink(CID.parse(cidStr.trim()));
    } catch {
      return undefined;
    }
  }

  async #invocationConfig(client, resource, abilities) {
    const issuer = client.agent.issuer;
    const proofs = await client.agent.proofs(
      abilities.map((can) => ({ can, with: resource }))
    );
    const audience = client._serviceConf.upload.id;
    const conf = { issuer, with: resource, proofs, audience };
    return conf;
  }

  async #uploadDirectory(client, space = null, files, options = {}) {
    const resource = space || client.currentSpace().did();
    if (resource === client.currentSpace().did()) {
      const cid = await client.uploadDirectory(files, options);
      return cid.toString();
    }

    options.connection = client._serviceConf.upload;

    const abilities = [StoreCapabilities.add.can, UploadCapabilities.add.can];

    const conf = await this.#invocationConfig(client, resource, abilities);

    const cid = await uploadDirectory(conf, files, options);
    return cid.toString();
  }

  async #uploadFile(client, space = null, file, options = {}) {
    const resource = space || client.currentSpace().did();
    if (resource === client.currentSpace().did()) {
      const cid = await client.uploadFile(file, options);
      return cid.toString();
    }

    options.connection = client._serviceConf.upload;

    const abilities = [StoreCapabilities.add.can, UploadCapabilities.add.can];

    const conf = await this.#invocationConfig(client, resource, abilities);

    const cid = await uploadFile(conf, file, options);
    return cid.toString();
  }

  async #list(client, space, options = {}) {
    const resource = space || client.currentSpace().did();
    // if (resource === client.currentSpace()) {
    //     return await client.list();
    // }
    if (resource === client.currentSpace().did()) {
      return await client.capability.upload.list(options);
    }

    options.connection = client._serviceConf.upload;

    const conf = await this.#invocationConfig(client, resource, [
      UploadCapabilities.list.can,
    ]);

    return Upload.list(conf, options);
  }

  async #remove(client, space, root, options = {}) {
    const resource = space || client.currentSpace().did();
    if (resource === client.currentSpace().did()) {
      return await client.capability.upload.remove(root, options);
    }

    options.connection = client._serviceConf.upload;

    const conf = await this.#invocationConfig(client, resource, [
      UploadCapabilities.remove.can,
    ]);

    return Upload.remove(conf, root, options);
  }

  #isObjectEmpty(obj) {
    return (
      !obj || (Object.keys(obj).length === 0 && obj.constructor === Object)
    );
  }

  did() {
    return this.#client.did();
  }

  async authenticate(email = null) {
    const isAuthenticated = await this.isAuthenticated();
    if (isAuthenticated) {
      const account = this.account(email);
      if (account) {
        localStorage.setItem("w3up-account-email", email);
        return account;
      }
    }

    const account = await this.#client.login(email);

    localStorage.setItem("w3up-account-email", email);
    return account;
  }

  async isAuthenticated() {
    if (window.appConfig.web3StorageAccessMethod === Web3StorageAccessMethod.Shared) {
      const email = localStorage.getItem("w3up-account-email");
      return email ? true : false;
    }

    const account = this.account();
    if (account) {
      return true;
    } else {
      return false;
    }
  }

  async loadAgent() {
    if (!window.wallet || !(await window.wallet.isConfigured())) {
      console.warn("Wallet not ready");
      return;
    }
    if (this.#client) {
      return this.#client;
    }

    let principal = await window.wallet.getAgent();
    if (principal) {
      principal = Signer.parse(principal);
    } else {
      principal = await Signer.generate();
      const key = Signer.format(principal);
      await window.wallet.setAgent(key);
    }
    // if (principal) {
    //     principal = Signer.parse(principal);
    // } else {
    //     if (window.wallet && window.wallet.asDefaultWallet) {
    //         const res = await window.wallet.rpcProvider.request({method: 'af_cryptoService', params: ['getW3UPSignKey', 0]});
    //         // const foldersPriKeyBuffer = keys.marshalPrivateKey(res);
    //         principal = await Signer.derive(res);
    //     }

    //     // principal = await Signer.generate()
    // }

    // const store = new StoreIndexedDB("w3up@pleximail");
    // const store = new W3upStoreIndexedDB("w3up@pleximail");

    const store = window.appConfig.privacyLevelIsNormal
      ? new W3upStoreIndexedDB("w3up@pleximail")
      : new W3upStoreInMemory();
    this.#store = store;

    const client = await create({
      principal,
      store: store,
    });
    this.#client = client;

    // const raw = await store.load();
    // if (raw) {
    //     const client = await create({
    //         store: store,
    //     });
    //     this.#client = client;
    // } else {
    //     const principal = Signer.parse(
    //         "MgCalW1sF8oO/DNO234ByFEt0d0kT0WA/6jNKo+aVOREAAO0Boom5/oneNl+u4ZEYGWpy9IBG9Tpxd38qr8B1laVZj6c="
    //     ); // created by `npx ucan-key ed --json` in command line
    //     const client = await create({
    //         principal: principal,
    //         store: store,
    //     });
    //     this.#client = client;
    // }
    return client;
  }

  async unloadAgent() {
    this.client = null;
    this.#store = null;
  }

  async reset() {
    const client = this.#client;
    const store = this.#store;

    if (client) {
      await this.unloadAgent();
    }

    if (store) {
      await store.reset();
    }
  }
  async forceReset() {
    await this.unloadAgent();

    const store = window.appConfig.privacyLevelIsNormal
      ? new W3upStoreIndexedDB("w3up@pleximail")
      : new W3upStoreInMemory();
    await store.reset();

    
  }

  spaces() {
    return this.#client.spaces();
  }

  currentSpace() {
    return this.#client.currentSpace();
  }

  setCurrentSpace(did) {
    this.#client.setCurrentSpace(did);
  }

  async getStorageUsage() {
    throw Error("getStorageUsage failed");
  }

  async setupBilling(space, customer, pcb) {
    const account = this.account(customer);
    if (account) {
      let plan = null;
      // let lastSeconds = 900;
      const startTime = new Date().getTime();
      while (!plan) {
        const result = await account.plan.get();
        if (result.ok) {
          plan = result.ok;
        } else {
          const now = new Date().getTime();
          const timeCost = Math.round((now - startTime) / 1000);

          const lastSeconds = 900 - timeCost;
          if (lastSeconds <= 0) {
            return { error: { reason: "error", cause: "Timeout" } };
          }
          const m = lastSeconds > 60 ? Math.floor(lastSeconds / 60) : 0;
          const s = lastSeconds % 60;

          pcb &&
            pcb(
              2,
              `Please select the payment in ${
                m > 0 ? (m > 1 ? m + " minutes and" : m + " minute and") : ""
              } ${s > 1 ? s + " seconds" : s + " second"}`
            );

          await new Promise((resolve) => {
            setTimeout(resolve, 1000);
          });
        }
      }

      const result = await account.provision(space);

      if (result.error) {
        return { error: { reason: "error", cause: result.error } };
      } else {
        return { ok: {} };
      }
    } else {
      return { error: { reason: "abort" } };
    }
  }
  async createRecovery(space) {
    const account = this.account();

    const recovery = await space.createRecovery(account.did());

    const result = await this.#client.capability.access.delegate({
      space: space.did(),
      delegations: [recovery],
    });
    if (result.ok) {
      console.log(`✨ Account is authorized`);
    } else {
      console.error(
        `⚠️ Failed to authorize account. You can still manage space using "paper key"`
      );
      console.error(result.error);
    }
  }

  async createSpace(name, pcb) {
    pcb && pcb(1, "Waiting for Web3Storage space creation...");

    const space = await this.#client.createSpace(
      name ? `pleximail.${name}` : "pleximail"
    );

    const authorization = await space.createAuthorization(this.#client);
    const sharedSpace = await this.#client.addSpace(authorization);
    console.log("new space created:", sharedSpace);

    pcb && pcb(2, "Waiting for payment plan to be selected in 15 minutes...");

    const res = await this.setupBilling(space.did(), null, pcb);
    if (res.error) {
      throw new Error(res.error.reason);
    }

    const account = this.account();
    if (account && space) {
      pcb && pcb(3, "Waiting for account authorization...");

      const recovery = await space.createRecovery(account.did());

      const result = await this.#client.capability.access.delegate({
        space: space.did(),
        delegations: [recovery],
      });
      if (result.ok) {
        console.log(`✨ Account is authorized`);
      } else {
        console.error(
          `⚠️ Failed to authorize account. You can still manage space using "paper key"`
        );
        console.error(result.error);
      }
    }
    pcb && pcb(4, "Succeed");

    return space;
  }

  getSpaceByName(name) {
    name = name ? `pleximail.${name}` : "pleximail";
    const spaces = this.spaces();
    for (const space of spaces) {
      if (space.name === name) {
        return space;
      }
    }
    return null;
  }

  async checkSpace(space, pcb) {
    // const account = this.account();
    // const result = await account.plan.get()
    // if (result.ok) {
    //     pcb && pcb(3, 'Waiting for account authorization...');
    //     const recovery = await space.createRecovery(account.did())
    //     const result = await this.#client.capability.access.delegate({
    //         space: space.did(),
    //         delegations: [recovery],
    //     })
    //     if (result.ok) {
    //         console.log(`✨ Account is authorized`)
    //     } else {
    //         console.error(
    //             `⚠️ Failed to authorize account. You can still manage space using "paper key"`
    //         )
    //         console.error(result.error)
    //     }
    // } else {
    //     pcb && pcb(2, 'Waiting for payment plan to be selected in 15 minutes...');
    //     const res =await this.setupBilling(space.did(), null, pcb);
    //     if (res.error) {
    //         throw new Error(res.error.reason);
    //     }
    //     if (account && space) {
    //         pcb && pcb(3, 'Waiting for account authorization...');
    //         const recovery = await space.createRecovery(account.did())
    //         const result = await this.#client.capability.access.delegate({
    //             space: space.did(),
    //             delegations: [recovery],
    //         })
    //         if (result.ok) {
    //             console.log(`✨ Account is authorized`)
    //         } else {
    //             console.error(
    //                 `⚠️ Failed to authorize account. You can still manage space using "paper key"`
    //             )
    //             console.error(result.error)
    //         }
    //     }
    // }
  }

  async createAndShareSpace(name, audience, encoding = "base64", pcb = null) {
    const currentSpace = this.currentSpace();
    try {
      const space = await this.createSpace(name, pcb);
      await this.setCurrentSpace(space.did());

      const res = await this.createDelegation(
        audience,
        ["upload/add", "upload/remove", "store/add", "store/remove"],
        encoding
      );
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      if (currentSpace) {
        await this.setCurrentSpace(currentSpace.did());
      }
    }
  }
  async register(email) {
    // const account = this.account(email);
    // const space = this.currentSpace();
    // if (account && space) {
    //     await account.provision(space.did());
    // }
    // const space = this.currentSpace();
    // const account = this.account();
    // if (account && space) {
    //     const recovery = await space.createRecovery(account.did())
    //     const result = await this.#client.capability.access.delegate({
    //         space: space.did(),
    //         delegations: [recovery],
    //     })
    //     if (result.ok) {
    //         console.log(`✨ Account is authorized`)
    //     } else {
    //         console.error(
    //             `⚠️ Failed to authorize account. You can still manage space using "paper key"`
    //         )
    //         console.error(result.error)
    //     }
    // }
  }

  isSpaceRegistered() {
    const space = this.currentSpace();
    if (!space) {
      return false;
    }
    return true;
    // return space.registered()
  }

  async put(files, options) {
    if (USE_DAG_FILE) {
      if (!(files instanceof Array) && files.constructor !== Array) {
        files = [files];
      }

      return await this.#uploadDirectory(
        this.#client,
        options && options.space,
        files,
        options
      );
    } else {
      if (files instanceof Array || files.constructor === Array) {
        files = files[0];
      }

      return await this.#uploadFile(
        this.#client,
        options && options.space,
        files,
        options
      );
    }
  }

  async list(options) {
    return await this.#list(this.#client, options && options.space, options);
  }

  async remove(cid, options) {
    // const root = this.#parseCarLink(cid);
    const root = CID.parse(cid.trim());
    if (root) {
      await this.#remove(this.#client, options && options.space, root, options);
    }
  }

  async #toCarBlob(delegation) {
    const { writer, out } = CarWriter.create();
    for (const block of delegation.export()) {
      // @ts-expect-error
      void writer.put(block);
    }
    void writer.close();

    const carParts = [];
    for await (const chunk of out) {
      carParts.push(chunk);
    }
    const car = new Blob(carParts, {
      type: "application/vnd.ipld.car",
    });
    return car;
  }

  async #toDelegation(bytes) {
    const blocks = [];
    const reader = await CarReader.fromBytes(bytes);
    for await (const block of reader.blocks()) {
      blocks.push(block);
    }
    return importDAG(blocks);
  }

  /**
   * const ucan = await mailService.web3StorageClient.createDelegation(
   *  'did:key:z6Mki4YdPuh8G8SvzExydtCqFbSzF5oFww4Kwg6Y8dpgzf7x',
   *  ['upload/add', 'store/add', 'upload/remove'],
   *  'base64'
   * )
   */

  async createDelegationWithSpace(
    space,
    did,
    abilities = ["*"],
    encoding = "raw",
    options = { expiration: Infinity }
  ) {
    // const audienceMeta = options.audienceMeta ?? {
    //     name: 'agent',
    //     type: 'device',
    // }
    // const audience = DID.parse(did);
    // options = {
    //     ...options,
    //     abilities,
    //     audience,
    //     audienceMeta,
    // }
    // const caps = /** @type {API.Capabilities} */ (options.abilities.map((a) => {
    //     return {
    //         with: space.did,
    //         can: a,
    //     };
    // }));
    // // Verify agent can provide proofs for each requested capability
    // for (const cap of caps) {
    //     if (!this.proofs([cap]).length) {
    //         throw new Error(`cannot delegate capability ${cap.can} with ${cap.with}`);
    //     }
    // }
    // const delegation = await delegate({
    //     issuer: this.#client.agent.issuer,
    //     capabilities: caps,
    //     proofs: this.#client.agent.proofs(caps),
    //     facts: [{ space: space.meta ?? {} }],
    //     ...options,
    // });
    // await this.#client.agent.#data.addDelegation(delegation, {
    //     audience: options.audienceMeta,
    // });
    // await this.removeExpiredDelegations();
    // return delegation;
  }
  async createDelegation(
    did,
    abilities = ["*"],
    encoding = "raw",
    options = { expiration: Infinity }
  ) {
    // const audience = DID.parse(did)
    // const res = await this.#client.createDelegation(audience, abilities, options);

    // const archived = await res.archive();
    // if (encoding === 'base64') {
    //     return encodeBase64(archived.ok);
    // }
    // return archived.ok;

    const audience = DID.parse(did);
    const audienceMeta = options.audienceMeta ?? {
      name: "agent",
      type: "device",
    };

    const delegation = await this.#client.agent.delegate({
      ...options,
      abilities,
      audience,
      audienceMeta,
    });
    const blob = await this.#toCarBlob(delegation);
    const archived = new Uint8Array(await blob.arrayBuffer());
    if (encoding === "base64") {
      return encodeBase64(archived);
    }
    return archived;
  }

  async revokeDelegation(cid, options = {}) {
    this.#client.revokeDelegation(cid, options);
  }

  async toDelegation(ucan, encoding = "base64") {
    if (encoding === "base64") {
      ucan = decodeBase64(ucan);
    }
    const delegation = await this.#toDelegation(ucan);
    return delegation;
  }
  
  async testRequestStorage(did) {

    // const result = window.plexiMailService.requestStorage(did, version);
    // let result = await fetch( + 'https://storage.ai-fi.cc/w3up/delegate/v1', {
    // let result = await fetch('/w3up/delegate/v1', {// http://127.0.0.1:3011/w3up/delegate/v1
    let result = await fetch('http://127.0.0.1:3011/w3up/delegate/v1', {// 
      method: "POST", 
      mode: "cors",
      headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer '
      },
      body: JSON.stringify({did: did})
    }).then(resp => {
      if (resp.status === 200) {
          return resp.json();
      }
      return {code: (resp.status > 0 ? -resp.status : resp.status), msg: resp.statusText};
    });

    if (result.code !== 0) {
      throw ServerError.from(result);
    }
    return result.data;
  }
  async requestAuthorization(version=1) {
    const cachedStorage = localStorage.getItem('app.shared-storage');
    if (cachedStorage && cachedStorage.length > 0) {
      return cachedStorage;
    }
    
    let principal = await window.wallet.getAgent();
    if (principal) {
      principal = Signer.parse(principal);
    } else {
      principal = await Signer.generate();
      const key = Signer.format(principal);
      await window.wallet.setAgent(key);
    }

    if (!principal) {
      return null;
    }

    const did = principal.did();
    const storage = await window.plexiMailService.requestWeb3Storage(did);


    localStorage.setItem("app.shared-storage", storage);
    return storage;
  }

  async saveSharedEmail() {
    localStorage.setItem("w3up-account-email", "shared-pleximail-storage@ai-fi.cc");
  }

  async addSpace(ucan, encoding = "raw", options = {}) {
    const delegation = await this.toDelegation(ucan, encoding);
    const facts = delegation.facts;
    if (!facts || facts.length === 0) {
      options.name = "imported";
    } else {
      const name = facts[0].space.name;
      if (!name || name.length === 0) {
        options.name = "imported";
      }
    }
    return await this.#client.agent.importSpaceFromDelegation(
      delegation,
      options
    );

    // return await this.#client.addSpace(delegation);

    // proof = await extract(proof);
    // const delegation = proof.ok;
    // if (options.name && options.name.length > 0) {
    //     return await this.#client.agent.importSpaceFromDelegation(delegation, options)
    // }
    // return await this.#client.addSpace(delegation);
  }

  async addSpaceV2(ucan, encoding = "raw", options = {}) {
    if (encoding === "base64") {
      ucan = decodeBase64(ucan);
    }

    // Deserialize the delegation
    const delegationResult = await Delegation.extract(ucan);
    if (!delegationResult.ok) {
      throw new Error("Failed to extract delegation", {
        cause: delegationResult.error,
      });
    }
    const delegation = delegationResult.ok;

    const facts = delegation.facts;
    if (!facts || facts.length === 0) {
      options.name = "imported";
    } else {
      const name = facts[0].space.name;
      if (!name || name.length === 0) {
        options.name = "imported";
      }
    }

    const space = await this.#client.addSpace(delegation.ok);
    this.#client.setCurrentSpace(space.did());
  }



  async generateTokens(expiration = 0) {
    const client = this.#client;
    const resource = this.currentSpace().did();
    const resourceDID = DID.parse(resource);

    const can = [
      "space/blob/add",
      "space/index/add",
      "filecoin/offer",
      "upload/add",
      "store/add"
    ]
    const abilities = can ? [can].flat() : [];
    if (!abilities.length) {
      console.error("Error: missing capabilities for delegation");
      throw new Error('Error: missing capabilities for delegation');
    }

    const capabilities = /** @type {ucanto.API.Capabilities} */ (
      abilities.map((can) => ({ can, with: resourceDID.did() }))
    );

    const password = cryptoRandomString(32);

    const coupon = await client.coupon.issue({
      capabilities,
      expiration: expiration === 0 ? Infinity : expiration,
      password,
    });

    const { ok: bytes, error } = await coupon.archive();
    if (!bytes) {
      console.error(error);
      throw error;
    }
    const xAuthSecret = encodeBase64(new TextEncoder().encode(password));
    const authorization = encodeBase64(bytes);

    return {
      "X-Auth-Secret": xAuthSecret,
      Authorization: authorization};
  }
}

export default W3UpWeb3Storage;
