export const SetupWizardState = {
    ConnectWallet: 1,
    SelectSetupMethod: 2,
    SelectBackupMethod: 3,
    SelectRecoveryMethod: 4,
    ConfigPassword: 5,
    EnterPassword: 6,
    GenerateMnemonic: 7,
    RestoreMnemonic: 8,
    ConfigWeb3Storage: 9,
    RestoreWeb3Storage: 10,
    ConfigSignal: 11,
    ExportConfig: 12,
    WaitForTransactionReceipt: 13,
    Completed: 14
};

export const StorageProvider = {
    Unspecified: 0,
    LightHouse: 1,
    Web3Storage: 2,
};
export const Web3StorageAccessMethod = {
    Shared: 1,
    Private: 2
};

export const PlexiMailStatus = {
    WaitForNew: 0,
    WaitForOneClickNew: 1,
    WaitForRecovery: 2,
    WaitForOneClickRecovery: 3,
    Ready: 4
}
export const MnemonicBackupMethod = {
    Web3Storage: 1,
    LocalFile: 2,
}
export const MailboxType = {
    plexi: 1,
    normal: 2
};

export const MailEncryptionType = {
    signal: 'sig',
    password: 'pwd',
    mixed: 'mix',
    plaintext: 'pt'
};


export const SignalInitStep = {
    CheckPreKeyCount: 1,
    GenerateKeys: 2,
    SavePreKeyBundle: 3,
    SaveIdentityKey: 4,
    // WaitTransactionReceipt: 5,
    SaveSignalProtocolStore: 5,
    Completed: 6,
};

export const RecoveryState = {
    ReadyToRecovery: 0,
    RecoveryWeb3Storage: 1,
    RetrieveFolders: 2,
    RecoverySignal: 3,
    Completed: 4
}

export const SignalMessageType = {
    WhisperMessage: 1,
    PreKeyWhisperMessage: 3
};


export const EthereumChains = {
    'Default': '0xa86a',
    // 'Ethereum Mainnet': '0x1',
    'Mainnet': '0xa86a',
    'Testnet': '0xa869',
    
    'EthereumMainnet': '0x1',
    'Goerli': '0x5',
    'Sepolia': '0xaa36a7',
    'Fuji': '0xa869',
    'Avalanche': '0xa86a',
    // '0x1': 'Ethereum Mainnet',
    '0x1': 'Mainnet',
    '0x5': 'Goerli',
    '0xaa36a7': 'Sepolia',
    '0xa869': 'Fuji',
    '0xa86a': 'Avalanche',
};

export const EthereumFaucets = {
    '0x1': 'https://ethereum.org/en/get-eth',
    '0x5': 'https://goerlifaucet.com',
    '0xaa36a7': 'https://sepoliafaucet.com',
    '0xa869': 'https://faucet.avax.network',
    '0xa86a': 'https://transak.com/'
}

export const DEFAULT_CHAIN_ID = EthereumChains.Mainnet;

export const SmartContractAddresses = {
    '0x1': '0xB1e6De50e2A121951C39077f8421b67aE1b264fC', // TODO
    '0x5': '0xEFE0B5b1C6c8E8623897Cf3ED87ee967C58a018F',
    '0xaa36a7': '0xF720853507EA9Ed14A96571aa9189d3bF6cDd32F',
    '0xa869': '0xB3BCd4Cfef3215dc4352E8Fa34fB2fF777Ca3Bca',
    '0xa86a': '0xB1e6De50e2A121951C39077f8421b67aE1b264fC'
};

export const IdentityKeyStatus = {
    Changed: 1,
    Unchanged: 2,
    NotExist: 3,
}


export const MessageFlag = {
    MessageFlagNone          : 0,
    MessageFlagSeen          : 1 << 0,
    MessageFlagAnswered      : 1 << 1,
    MessageFlagFlagged       : 1 << 2,
    MessageFlagDeleted       : 1 << 3,
    MessageFlagDraft         : 1 << 4,
    MessageFlagMDNSent       : 1 << 5,
    MessageFlagForwarded     : 1 << 6,
    MessageFlagSubmitPending : 1 << 7,
    MessageFlagSubmitted     : 1 << 8,
    MessageFlagDecrypted     : 1 << 9,
    MessageFlagMaskAll : (1 << 0) | (1 << 1) | (1 << 2) |
    (1 << 3) | (1 << 4) | (1 << 5) | (1 << 6) |
    (1 << 7) | (1 << 8) | (1 << 9)
} ;

export const FolderType = {
    inbox: 'inbox',
    contract: 'contract',
    sent: 'sent',
    trash: 'trash',
    custom: 'custom'
};

export const SyncDirection = {
    Export: 0,
    Import: 1,
}

export const ContractConstants = {
    ThreadMode: {
        ContractThread: 1,
        QuickSign: 2,
    },
    Actions: {
        CreateThread: 1,
        CreateDiscussion: 2,
        ReplyDiscussion: 3,
        CreateSignatureRound: 4,
        SignSignatureRound: 5,
        EndSignatureRound: 6,
        CancelSignatureRound: 7,
        // action: "quick-sign|create-new-thread|create-discussion|reply-discussion|create-signature-round|sign-signature-round|end-signature-round",
            
    },
    SignStep: {
        GetStarted: 1,
        Changes: 2,
        PII: 3,
        // Check: 4,
        DigitalSign: 4,
        PhoneNumber: 5,
        Voice: 6,
        Video: 7,
        Handwritten: 8,
        Tsa: 9,
        Send: 10
    }
}