// import './App.css';
import React, { useEffect, useState } from "react";

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  // Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
  // AvatarBadge,
  // Popover,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // PopoverCloseButton,
  // ListItem,
  // PopoverHeader,
  // PopoverBody,
  // ListIcon,
  Badge,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  // PopoverCloseButton,
  // PopoverHeader,
  PopoverBody,
  FormControl,
  FormLabel,
  Input,
  Stack,
  PopoverFooter,
  ButtonGroup,
  PopoverCloseButton,
  PopoverHeader,
  Center,
  Divider,
  SimpleGrid,
  // useBreakpointValue,
} from "@chakra-ui/react";

import {
  // FiHome,
  // FiTrendingUp,
  // FiCompass,
  // FiStar,
  FiSettings,
  FiMenu,
  // FiBell,
  FiChevronDown,
  FiSend,
  FiTrash,
  FiMail,
  FiAlertOctagon,
  FiArchive,
  FiUser,
  // FiUsers,
  FiPower,
  FiFolder,
  FiHelpCircle,
  FiGlobe,
  FiPlus,
  FiAtSign,
  FiBook,
  FiLock,
  FiUserCheck,
  FiToggleLeft,
  FiShare,
  FiFile,
  FiPlay,
  FiEdit3,
  FiTarget,
  FiFolderPlus,
  FiGrid,
  FiCopy,
  FiCloud,
  FiCalendar,
  FiClipboard,
  FiSave,
  FiX,
  FiEdit,
  // FiLayout,
  // FiGlobe,
  // FiRepeat,
} from "react-icons/fi";
import { FaAddressBook, FaRegAddressBook } from "react-icons/fa";
import { AiOutlineDashboard } from "react-icons/ai";
import * as ReactIcons from "react-icons/fa";
// import * as RI from 'react-icons/ri';
// import * as ReactFcIcons from 'react-icons/fc';
import { BsWallet } from "react-icons/bs";
import {MdGroup, MdOutlineAlternateEmail} from "react-icons/md";
import {HiUser, HiUserGroup} from "react-icons/hi";

import {
  // List,
  List as ListLoader,
} from "react-content-loader";
import MailEditor from "./MailEditor";
import ProfileModal from "./ProfileModal";
import ContactModal from "./ContactModal";
import SettingsModal from "./SettingsModal";
import {
  BACKUP_FOLDER,
  HELP_DOC_URL,
  INBOX_FOLDER,
  SENT_FOLDER,
  TRASH_FOLDER,
  CONTRACT_FOLDER,
  mailAddressSuffix,
  ENTERPRISE_FEATURES_ENABLED,
  mailAddressSubdomain,
  mailAddressDomain,
} from "../common/constants";
// import { getChainId } from 'web3modal';
import {
  ContractConstants,
  /*EthereumChains,*/ MessageFlag,
  SetupWizardState,
  StorageProvider,
} from "../utils/Types";
// import appConfig from '../config/AppConfig';
import { ClientError, showError, showInfo } from "../common/errors";
import FolderCreationModal from "./FolderCreationModal";
// import { TutorialModalMenuItem } from './TutorialModalTrigger';
import InstallAppAlert from './InstallAppAlert';
import Strings from "../config/Strings";
// import { EditIcon } from '@chakra-ui/icons';
// import { AiOutlineWallet } from "react-icons/ai";
// import { FaEthereum } from "react-icons/fa";
import BuyEthModal from "./BuyEthModal";
import AFWallet from "../wallet/AFWalletV2";
import EnterpriseModal from "./enterprise/EnterpriseModal";
// import UserModal from './enterprise/UserModal';
// import EnterpriseProfileAlert from './enterprise/EnterpriseProfileAlert';
// import AddressBookModal from './enterprise/AddressBookModal';

import { GoUnverified, GoVerified } from "react-icons/go";
import { RunModeSwitchButton } from "./RunModeSwitcherModal";
import MailService, { PLEXISIGN_IS_ENABLED } from "../services/MailService";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ContractThreadLevel } from "./contract/ContractCommon";
import ContractIntroAlert from './ContractIntroAlert';
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { LighthousePlaygroundMenuItem } from "./LighthousePlaygroundModal";
// import withIdentity from './w3ui/Authenticator'
// import { showError, showInfo } from '../common/errors';

//import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

// import TagEditor from "react-tageditor";
// import "react-tageditor/lib/style/default.css";

// const LinkItems = {
//   INBOX: FiMail,
//   Sent: FiSend,
//   Spam: FiAlertOctagon,
//   Trash: FiTrash,
//   Settings: FiSettings,
// };

const FolderIcons = {
  inbox: FiMail,
  sent: FiSend,
  spam: FiAlertOctagon,
  trash: FiTrash,
  settings: FiSettings,
  custom: FiFolder,
  draft: FiFile,
  contract: FiArchive,
  contract_initialized: FiPlay,
  contract_signing: FiEdit3,
  contract_completed: FiTarget,
  contract_archived: FiArchive,
};

const ENTERPRISE_ENABLED = true;

const SidebarWithHeader = ({
  folderVersion,
  selectedAccount,
  setSelectedAccount,
  buyDisclosure,
  // faucetDisclosure,
  setSetupWizardState,
  folders,
  setFolders,
  setCurrentFolder,
  setMessages,
  setMessage,
  // composeDisclosure,
  children,
  verified,
}) => {

  console.log(">> SidebarWithHeader");

  const getDefaultApp = () => {
    const appType = localStorage.getItem('app.default.app');
    try {
      let type =  parseInt(appType);
      if (type !== APP_TYPES.PLEXI_SIGN) {
        type = APP_TYPES.PLEXI_MAIL;
      }
      return APP_LIST[type]
    } catch (e) {
      console.error(e);
      return APP_LIST[APP_TYPES.PLEXI_MAIL];
    }
  }
  const [app, setApp] = useState(getDefaultApp());

  const [showGlobalSpinner, setShowGlobalSpinner] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();


  //   mailService.initFoldersIfNeeded().then((success) => {
  //     // setFolders(mailService.folders);
  //   })

  // useEffect(() => {
  //   console.log('SidebarWithHeader without params')

  // }, [])

  useEffect(() => {
    // showError('xxxxxxxxx')
    console.log("SidebarWithHeader with empty param");
  }); // eslint-disable-line react-hooks/exhaustive-deps

  // https://github.com/instructure-react/react-tokeninput
  // https://github.com/seawind543/react-token-input
  // https://www.npmjs.com/package/react-customize-token-input
  // bower install react-tageditor --save
  return (
    <>
      {showGlobalSpinner && (
        <VStack
          position={"absolute"}
          zIndex={10000}
          backgroundColor="rgba(0,0,0,0.3)"
          width={"100%"}
          height="100vh"
          alignItems={"center"}
          align={"center"}
          alignContent="center"
          justifyContent={"center"}
          justifyItems={"center"}
          verticalAlign="center"
        >
          <Spinner />
        </VStack>
      )}
      <Box
        minH="100vh"
        bg={useColorModeValue("whiteAlpha.100", "whiteAlpha.900")}
      >
        <SidebarContent
          folderVersion={folderVersion}
          verified={verified}
          selectedAccount={selectedAccount}
          folders={folders}
          buyDisclosure={buyDisclosure}
          // faucetDisclosure={faucetDisclosure}
          setFolders={setFolders}
          setCurrentFolder={setCurrentFolder}
          setMessages={setMessages}
          setMessage={setMessage}
          // composeDisclosure={composeDisclosure}
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          app={app}
          setApp={setApp}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            {folders && (
              <SidebarContent
                folderVersion={folderVersion}
                verified={verified}
                onDraverClose={onClose}
                selectedAccount={selectedAccount}
                folders={folders}
                setFolders={setFolders}
                setCurrentFolder={setCurrentFolder}
                setMessages={setMessages}
                setMessage={setMessage}
                onClose={onClose}
                app={app}
                setApp={setApp}
              />
            )}
          </DrawerContent>
        </Drawer>
        
        {/* mobilenav */}
        <MobileNav
          buyDisclosure={buyDisclosure}
          setSetupWizardState={setSetupWizardState}
          folders={folders}
          setFolders={setFolders}
          selectedAccount={selectedAccount}
          setCurrentFolder={setCurrentFolder}
          setMessages={setMessages}
          setMessage={setMessage}
          setShowGlobalSpinner={setShowGlobalSpinner}
          verified={verified}
          onOpen={onOpen}
          app={app}
          setApp={setApp}
        />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {children}
        </Box>
        {/* <Box 
      borderTopWidth="1px"
      borderTopColor={useColorModeValue('gray.200', 'gray.700')} 
      position={"fixed"} 

      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      alignItems="end"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={"flex-end"}
      lineHeight={"64px"}
      backgroundColor="red"
      bottom={"0"} 
      minW="100%" 
      height="64px"
      >
Powered by Ai-Fi.net
      </Box> */}
      </Box>
    </>
  );
};

const UnreadBadge = ({ badgeNumber }) => {
  if (badgeNumber <= 0) {
    return <></>;
  }
  return (
    <Badge ml={3} bg={"red.400"} color={"white"}>
      {badgeNumber}
    </Badge>
  );
};

const APP_TYPES = {
  PLEXI_MAIL: 0,
  PLEXI_SIGN: 1,
};

const APP_LIST = [
  {
    title: "PlexiMail",
    type: APP_TYPES.PLEXI_MAIL,
  },
  {
    title: "PlexiSign",
    type: APP_TYPES.PLEXI_SIGN,
  },
];

const SidebarContent = ({
  folderVersion,
  onDraverClose,
  selectedAccount,
  folders,
  buyDisclosure,
  // faucetDisclosure,
  setFolders,
  setCurrentFolder,
  setMessages,
  setMessage,
  // composeDisclosure,
  onClose,
  verified,
  app, 
  setApp,
  ...rest
}) => {

  const fillNameDisclosure = useDisclosure();
  const [fillNameTask, setFillNameTask] = useState(null);
  const [isComposeButtonHidden, setComposeButtonHidden] = useState(false);

  useEffect(() => {}, [verified]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.mailService.getPlexiMailUIState().then((uiState) => {
      if (
        uiState &&
        uiState.currentFolder !== CONTRACT_FOLDER &&
        uiState.editorIsOpened
      ) {
        composeDisclosure.onOpen();
      }
      setComposeButtonHidden(
        uiState && uiState.currentFolder === CONTRACT_FOLDER
      );

      if (app && app.type === APP_TYPES.PLEXI_SIGN) {
        window.mailService.contractFilter = null;
        loadMessages(CONTRACT_FOLDER);
      } else {
        loadMessages(INBOX_FOLDER);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [idleTimeout, setIdleTimeout] = useState(false);
  const composeDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {
      console.log("Mail Editor is Open");
      window.setIdleTimeout = setIdleTimeout;

      window.mailService
        .getPlexiMailUIState()
        .then((uiState) => {
          if (!uiState) {
            uiState = {
              currentFolder: window.mailService.currentFolder,
              editorIsOpened: true,
              threadContext: null,
            };
          } else {
            uiState.editorIsOpened = true;
          }
          return window.mailService.savePlexiMailUIState(uiState);
        })
        .then(() => {})
        .catch((e) => {
          console.error(e);
        });
    },
    onClose: () => {
      console.log("Mail Editor is Closed");
      delete window.setIdleTimeout;

      window.mailService
        .getPlexiMailUIState()
        .then((uiState) => {
          if (!uiState) {
            uiState = {
              currentFolder: window.mailService.currentFolder,
              editorIsOpened: false,
              threadContext: null,
            };
          } else {
            uiState.editorIsOpened = false;
          }
          return window.mailService.savePlexiMailUIState(uiState);
        })
        .then(() => {})
        .catch((e) => {
          console.error(e);
        });
    },
  });

  const folderCreationDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {
      console.log("Folder Creation Modal is Open");
    },
    onClose: () => {
      console.log("Folder Creation Modal is Closed");
    },
  });

  const S = Strings.main;
  async function loadMessages(folder) {
    setCurrentFolder(folder);

    if (folder !== CONTRACT_FOLDER) {
        
      let uiState = await window.mailService.getPlexiMailUIState();
      if (!uiState) {
        uiState = {
          currentFolder: folder,
          editorIsOpened: false,
          threadContext: null,
        };
      } else {
        uiState.currentFolder = folder;
      }
      await window.mailService.savePlexiMailUIState(uiState);

    }
    setComposeButtonHidden(folder === CONTRACT_FOLDER);

    setCurrentFolder(folder);

    // window.mailService.currentFolder = folder;
    // setMessages(null);
    if (folder === INBOX_FOLDER) {
      // let mailService = new MailService();
      // mailService.folders = folders;
      //setFolders(mailService.folders)
    }

    const folderObject = folders[folder];
    setMessage(null);

    if (folder === CONTRACT_FOLDER) {
      // setMessages([...messages]);
      setMessages([]);
      return;
    }

    const messages = Object.values(folderObject.messages)
      .sort((a, b) => {
        return b.sendDate - a.sendDate;
      })
      .filter((message) => {
        return !window.mailService.messageIsDeleted(message.uid);
      });
    setMessages([...messages]);
    console.log("load messages in ", folder);
  }
  function showCreateFolderModal() {
    folderCreationDisclosure.onOpen();
  }
  function unreadMessageCount(messages) {
    return Object.values(messages).filter((message) => {
      const flags = message.flags || MessageFlag.MessageFlagNone;
      const seen =
        (flags & MessageFlag.MessageFlagSeen) === MessageFlag.MessageFlagSeen;
      return !seen;
    }).length;
  }
  function isActived(folder) {
    if (!window.mailService || !window.mailService.currentFolder) {
      if (folder === INBOX_FOLDER) {
        return true;
      }
    }
    return folder === window.mailService.currentFolder;
  }

  function isContractFolderActived(state) {
    if (!window.mailService.contractFilter) {
      if (state === 'all') {
        return true;
      }
      return false;
    }

    return state === window.mailService.contractFilter.state;
  }

  const calUnreadNumber = (contracts) => {
    if (!contracts) {
      return 0;
    }
    contracts = Object.values(contracts);
    let count = 0;

    for (const contract of contracts) {
      const discussionsUnreadMessagesCount = Object.values(
        contract.discussions
      ).filter((message) => {
        return (message.flags & MessageFlag.MessageFlagSeen) === 0;
      }).length;
      const signatureRoundsUnreadMessagesCount = Object.values(
        contract.signatureRounds
      )
        .map((signatureRound) => {
          const count = Object.values(signatureRound.timeline).filter(
            (message) => {
              return (message.flags & MessageFlag.MessageFlagSeen) === 0;
            }
          ).length;
          return count;
        })
        .reduce((sum, value) => {
          return sum + value;
        }, 0);
      count +=
        discussionsUnreadMessagesCount + signatureRoundsUnreadMessagesCount;
    }
    return count;
  };

  const switchApp = (type) => {
    setApp(APP_LIST[type]);
    localStorage.setItem('app.default.app', type)
  };



  const quickSign = async () => {
    const threadID = window.mailService.generateUUIDSync();
    if (window.setThreadContextAsync) {
      await window.setThreadContextAsync({
        editMode: "new",
        contentOfMessage: null,
        level: ContractThreadLevel.Threads,
        mode: ContractConstants.ThreadMode.QuickSign,
        action: ContractConstants.Actions.CreateSignatureRound,
        id: threadID,
        data: {
          id: threadID,
        },
      });
    }
    if (window.openContractEditor) {
      window.openContractEditor();
    }
    // composeDisclosure.onOpen();

  };
  const createNewThread = async() => {
    const threadID = window.mailService.generateUUIDSync();
    if (window.setThreadContextAsync) {
      await window.setThreadContextAsync({
        editMode: "new",
        contentOfMessage: null,
        level: ContractThreadLevel.Threads,
        mode: ContractConstants.ThreadMode.ContractThread,
        action: ContractConstants.Actions.CreateThread,
        id: threadID,
        data: {
          id: threadID,
        },
      });
    }
    if (window.openContractEditor) {
      window.openContractEditor();
    }
    // composeDisclosure.onOpen();
  };

  const showContractIntroIfNeeded = async () => {
    if (localStorage.getItem('signature.round.intro.disabled') === 'true') {
      return;
    }

    const task = {}
    const promise = new Promise((resolve, reject) => {
      task.resolve = resolve;
      task.reject = reject;
    })
    task.promise = promise;

    setFillNameTask(task);

    fillNameDisclosure.onOpen();
    const disabled = await promise;
    localStorage.setItem('signature.round.intro.disabled', disabled);
  }

  const folderToLoad = () => {
    if (app.type === APP_LIST[0].type) {
      return INBOX_FOLDER;
    }
    if (app.type === APP_LIST[1].type) {
      return CONTRACT_FOLDER;
    }
    return INBOX_FOLDER;
  };

  useEffect(() => {
    if (folderToLoad()) {
      loadMessages(folderToLoad());
    }
  }, [app])
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    ><ContractIntroAlert disclosure={fillNameDisclosure} onCompleted={(disabled) => {
      fillNameTask && fillNameTask.resolve(disabled);
    }} />
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        {/* {verified && (
          <Text
            fontSize="2xl"
            fontFamily="monospace"
            fontWeight="bold"
            onClick={() => {
              window.open("https://ai-fi.cc");
            }}
            cursor={"pointer"}
          >
            {S.title}
          </Text>
        )} */}

        {verified && false && (
          <Stack>
            <Text
              fontSize="2xl"
              fontFamily="monospace"
              fontWeight="bold"
              onClick={() => {
                window.open("https://ai-fi.cc");
              }}
              cursor={"pointer"}
            >
              {S.title}
            </Text>
            <Popover>
              <PopoverTrigger>
                <Button
                  h={"20px"}
                  p={2}
                  borderRadius={"10px"}
                  variant="outline"
                  leftIcon={<GoVerified size={12} />}
                  fontSize={12}
                  colorScheme={"green"}
                >
                  {Strings.app.mode.verified}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color={"green"} fontWeight={"bold"}>
                  {Strings.app.dialog.address_verify_popup.verified.title}
                </PopoverHeader>
                <PopoverBody color={"green"}>
                  {
                    Strings.app.dialog.address_verify_popup.verified
                      .self_message
                  }
                </PopoverBody>
                {/* <PopoverFooter>
                  <Flex justify={"center"}>
                    <RunModeSwitchButton
                      buyDisclosure={buyDisclosure}
                      selectedAccount={selectedAccount}
                    >
                      {Strings.app.dialog.activate_account.trigger}
                    </RunModeSwitchButton>
                  </Flex>
                </PopoverFooter> */}
              </PopoverContent>
            </Popover>
          </Stack>
        )}
        {!verified && false && (
          <Stack>
            <Text
              fontSize="2xl"
              fontFamily="monospace"
              fontWeight="bold"
              onClick={() => {
                window.open("https://ai-fi.cc");
              }}
              cursor={"pointer"}
            >
              {S.title}
            </Text>
            <Popover>
              <PopoverTrigger>
                <Button
                  h={"20px"}
                  p={2}
                  borderRadius={"10px"}
                  variant="outline"
                  leftIcon={<GoUnverified size={12} />}
                  fontSize={12}
                  colorScheme={"orange"}
                >
                  {Strings.app.mode.unverified}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color={"orange"} fontWeight={"bold"}>
                  {Strings.app.dialog.address_verify_popup.unverified.title}
                </PopoverHeader>
                <PopoverBody color={"orange"}>
                  {
                    Strings.app.dialog.address_verify_popup.unverified
                      .self_message
                  }
                </PopoverBody>
                <PopoverFooter>
                  <Flex justify={"center"}>
                    <RunModeSwitchButton
                      buyDisclosure={buyDisclosure}
                      selectedAccount={selectedAccount}
                    >
                      {/* faucetDisclosure={faucetDisclosure} */}
                      {Strings.app.dialog.activate_account.trigger}
                    </RunModeSwitchButton>
                  </Flex>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </Stack>
        )}

        <Stack>
          <Menu>
            <MenuButton
              px={4}
              // py={2}
              title="In the left pane, this is the header for PlexiSign digital signature processes in states from draft to completed. Click to expand or contract this view."
              transition="all 0.2s"
              borderRadius={4}
              _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "#00aaff", color: "white" }}
              // _focus={{ boxShadow: 'outline' }}
            >
              <HStack>
                <Text
                  fontSize="2xl"
                  fontFamily="monospace"
                  fontWeight="bold"
                  // onClick={() => {
                  //   window.open("https://ai-fi.cc");
                  // }}
                  cursor={"pointer"}
                >
                  {app && app.title}
                </Text>{" "}
                <ChevronDownIcon />
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  switchApp(APP_LIST[0].type);

                  window.mailService.contractFilter = null;
                  // loadMessages(INBOX_FOLDER);
                }}
              >
                {APP_LIST[0].title}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  switchApp(APP_LIST[1].type);

                  window.mailService.contractFilter = null;
                  // loadMessages(CONTRACT_FOLDER);
                }}
              >
                {APP_LIST[1].title}
              </MenuItem>
            </MenuList>
          </Menu>
          <Popover>
              <PopoverTrigger>
                <Button
                  h={"20px"}
                  p={2}
                  borderRadius={"10px"}
                  variant="outline"
                  title={"Your identity has been verified by ICP who is a partner with PlexiSign in initiating, processing, completing and managing your digital signature workflows."}
                  leftIcon={<GoVerified size={12} />}
                  fontSize={12}
                  colorScheme={"green"}
                >
                  {Strings.app.mode.verified}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color={"green"} fontWeight={"bold"}>
                  {Strings.app.dialog.address_verify_popup.verified.title}
                </PopoverHeader>
                <PopoverBody color={"green"}>
                  {
                    Strings.app.dialog.address_verify_popup.verified
                      .self_message
                  }
                </PopoverBody>
                {/* <PopoverFooter>
                  <Flex justify={"center"}>
                    <RunModeSwitchButton
                      buyDisclosure={buyDisclosure}
                      selectedAccount={selectedAccount}
                    >
                      {Strings.app.dialog.activate_account.trigger}
                    </RunModeSwitchButton>
                  </Flex>
                </PopoverFooter> */}
              </PopoverContent>
            </Popover>
        </Stack>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {(!app || app.type === APP_TYPES.PLEXI_MAIL) &&

      <>
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        // hidden={isComposeButtonHidden}
      >
        {/* <ButtonGroup></ButtonGroup> */}
        
        <Button
          leftIcon={<ReactIcons.FaEdit />}
          width={"100vh"}
          // backgroundColor={"red.500"}
          backgroundColor={"cyan.100"}
          // colorScheme="cyan.100"
          // textColor="white"
          isDisabled={isComposeButtonHidden}
          onClick={composeDisclosure.onOpen}
        >
          Compose
        </Button>

        {/* const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'full'] */}
        <MailEditor
          selectedAccount={selectedAccount}
          setFolders={setFolders}
          composeDisclosure={composeDisclosure}
          editMode={"new"}
          idleTimeout={idleTimeout}
        />
      </Flex>
      <Flex alignItems={"end"} justifyContent={"flex-end"} mt={3}>
        <Tooltip label={S.sidebar.create}>
          <IconButton
            mr={1}
            // icon={<ReactIcons.FaPlus />}
            icon={<FiPlus />}
            onClick={showCreateFolderModal}
            spacing={{ base: "6", md: "6" }}
            bg={"none"}
          />
        </Tooltip>
        <FolderCreationModal
          disclosure={folderCreationDisclosure}
          setFolders={setFolders}
        />
      </Flex></>
      }
      {(app && app.type === APP_TYPES.PLEXI_SIGN) &&
    <>
    <HStack py={4}>
    <SimpleGrid width={"100%"} columns={2} spacing={2} 
        display={{ base: "none", md: "grid" }}>

      <Box pl={6}>
      <VStack border={"1px solid #ddd"} backgroundColor={"#efefef"} borderRadius={4} position={"relative"} color="green" cursor={"pointer"} _hover={{
        fontWeight: 'bold',
        textDecoration: 'underline',
        backgroundColor: '#a5c1a3',
      }} onClick={() => {
        
        if(window.quickSignWithAIAssistant) {

          showContractIntroIfNeeded().then(() => {
            return window.quickSignWithAIAssistant()
          }).catch(e => {
            showError(e);
          })
        } else {

          showContractIntroIfNeeded().then(() => {
            return quickSign();
          }).catch(e => {
            showError(e);
          })
        }
      }}>
          {/* <FiFolderPlus color="orange" size={40} /> */}
          {/* <MdGroup color="green" size={40} /> */}
          <HiUser color="green" size={40} />
        <Text color="green">QuickSign</Text>
      </VStack>
      </Box>

      <Box pr={6}>
      <VStack border={"1px solid #ddd"} backgroundColor={"#efefef"} borderRadius={4} position={"relative"} color="orange" cursor={"pointer"} _hover={{
        fontWeight: 'bold',
        textDecoration: 'underline',
        backgroundColor: '#e5d6bf',
      }} onClick={() => {
        
        createNewThread().then(() => {}).catch(e => {
          showError(e);
        })
      }}>
        {/* <FiEdit color="green" size={40} /> */}
        <HiUserGroup color="orange" size={40} />
        {/* <MdGroups color="orange" size={40}  /> */}
        {/* <Box width={"auto"} position={"absolute"} bottom={5} right={3}><FiLock color="red" /></Box> */}
        <Text color="orange">BulkSign</Text>
      </VStack>
      </Box>
      
    </SimpleGrid>

    </HStack>
        <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        display={{ base: "flex", md: "none" }}
        // hidden={isComposeButtonHidden}
        >
        {/* <ButtonGroup></ButtonGroup> */}
{/* 
        <Button
          // leftIcon={<ReactIcons.FaEdit />}
          width={"100vh"}
          // backgroundColor={"red.500"}
          backgroundColor={"cyan.100"}
          leftIcon={<FiPlus />}
          // colorScheme="cyan.100"
          // textColor="white"
          onClick={() => {
            if (window.openContractEditor) {
              window.openContractEditor()
              }
            }
          }
        >
          Initiate
        </Button> */}
        <Menu>
            <MenuButton
              px={4}
              aria-label="Launches a new digital signature workflow by bringing up the email client - ready to be populated and configured."
              title={"Launches a new digital signature workflow by bringing up the email client - ready to be populated and configured."}
              // py={2}
              transition="all 0.2s"
              borderRadius={4}
              // _hover={{ bg: "gray.400" }}
              // _expanded={{ bg: "#00aaff", color: "white" }}
              // _focus={{ boxShadow: 'outline' }}
            >
        <Button
          // leftIcon={<ReactIcons.FaEdit />}
          // width={"100vh"}
          // backgroundColor={"red.500"}
          // width={"200px"}
          px={"10"}
          backgroundColor={"cyan.100"}
          leftIcon={<FiPlus />}
        >
          Initiate
        </Button>
            </MenuButton>
            <MenuList>
              <MenuItem
                  icon={<FiEdit3 />}
                onClick={() => {
                  showContractIntroIfNeeded().then(() => {
                    return quickSign();
                  }).catch(e => {
                    showError(e);
                  })
                }}
              >
                Quick Sign
              </MenuItem>
              <MenuDivider />
              <MenuItem
                  icon={<FiFolderPlus />}
                onClick={() => {
                  createNewThread().then(() => {}).catch(e => {
                    showError(e);
                  })
                }}
              >
                 Bulk Sign Workflow
              </MenuItem>

              <MenuItem
                  icon={<FiEdit />}
                  onClick={() => {
                    if(window.quickSignWithAIAssistant) {
                      window.quickSignWithAIAssistant().then(() => {}).catch(e => {
                        showError(e);
                      })
                    }
                  }}
                >
                  Quick Sign (with AI Assistant)
                </MenuItem>
            </MenuList>
          </Menu>

        {/* const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'full'] */}
        <MailEditor
          selectedAccount={selectedAccount}
          setFolders={setFolders}
          composeDisclosure={composeDisclosure}
          editMode={"new"}
          idleTimeout={idleTimeout}
        />
        </Flex></>
      }

      {(!app || app.type === APP_TYPES.PLEXI_MAIL) &&
        folders &&
        Object.entries(folders).map((arr) => {
          // if (!PLEXISIGN_IS_ENABLED && arr[0] === CONTRACT_FOLDER) {
          //   return <></>;
          // }
          if (arr[0] === CONTRACT_FOLDER) {
            return <></>;
          }

          const unread =
            arr[0] === CONTRACT_FOLDER ? calUnreadNumber(arr[1].contracts) : 0;
          return (
            <NavItem
              folderId={arr[0]}
              folderName={arr[1].name}
              key={arr[0]}
              actived={isActived(arr[0])}
              app={app}
              icon={FolderIcons[arr[1].type || arr[0]]}
              onClick={() => {
                loadMessages(arr[0]);
                if (onDraverClose) {
                  onDraverClose();
                }
              }}
              onEdit={(isDelete, folderInfo, cb) => {
                if (isDelete) {
                  const keys = arr[1].messages
                    ? Object.keys(arr[1].messages)
                    : [];
                  if (keys && keys.length > 0) {
                    cb(
                      ClientError.invalidParameterError(
                        "Folders containing mail cannot be deleted"
                      )
                    );
                    return;
                  }
                  delete window.mailService.folders[folderInfo.id];
                } else {
                  window.mailService.folders[folderInfo.id].name =
                    folderInfo.name;
                }
                window.mailService
                  .syncFoldersFile()
                  .then(() => {
                    const newFolders = { ...window.mailService.folders };
                    delete newFolders[BACKUP_FOLDER];
                    setFolders(newFolders);
                    cb();
                  })
                  .catch((e) => {
                    cb(e);
                  });
              }}
            >
              {arr[1].name}
              {arr[0] !== SENT_FOLDER &&
                arr[0] !== TRASH_FOLDER &&
                arr[0] !== CONTRACT_FOLDER && (
                  <UnreadBadge
                    badgeNumber={unreadMessageCount(arr[1].messages)}
                  />
                )}
              {arr[0] === CONTRACT_FOLDER && unread > 0 && (
                <UnreadBadge badgeNumber={calUnreadNumber(arr[1].contracts)} />
              )}
              {/* <Badge ml={3}>{unreadMessageCount(arr[1].messages)}</Badge> */}
            </NavItem>
          );
        })}

      {app && app.type === APP_TYPES.PLEXI_SIGN && (
        <>
          <NavItem
            folderId={"all"}
            folderName={"all"}
            key={"all"}
            tips={'The “All” folder contains all digital contract workflows and processes you’ve initiated in any state from initiated to completed. Anything you’ve archived will only appear in the “Secure Archiving“ folder.'}
            actived={isContractFolderActived('all')}
            icon={FolderIcons.contract}
            app={app}
            onClick={() => {
              window.mailService.contractFilter = null;
              if (window.setThreadContextAsync) {
                window.setThreadContextAsync(  {
                  editMode: "new",
                  contentOfMessage: null,
                  level: ContractThreadLevel.Threads,
                }).then(() => {
                  loadMessages(CONTRACT_FOLDER);
                }).catch(e => {
                  console.error(e);
                  showError(e);
                });
              } else {
                loadMessages(CONTRACT_FOLDER);
              }
            }}
            onEdit={(isDelete, folderInfo, cb) => {}}
          >
            All
            <UnreadBadge badgeNumber={calUnreadNumber(folders[CONTRACT_FOLDER].contracts)} />
          </NavItem>
          <NavItem
            folderId={"initialed"}
            folderName={"Initialed"}
            key={"initialed"}
            tips={"The “Initiated” folder contains all digital contract workflows and processes that you’ve initiated but have not progressed to or beyond the Signing Round stage."}
            actived={isContractFolderActived('initialized')}
            icon={FolderIcons.contract_initialized}
            app={app}
            onClick={() => {
              window.mailService.contractFilter = {state: 'initialized'};
              if (window.setThreadContextAsync) {
                window.setThreadContextAsync(  {
                  editMode: "new",
                  contentOfMessage: null,
                  level: ContractThreadLevel.Threads,
                }).then(() => {
                  loadMessages(CONTRACT_FOLDER);
                }).catch(e => {
                  console.error(e);
                  showError(e);
                });
              } else {
                loadMessages(CONTRACT_FOLDER);
              }
              

            }}
            onEdit={(isDelete, folderInfo, cb) => {}}
          >
            Initiated
          </NavItem>
          <NavItem
            folderId={"signing_round"}
            folderName={"Signing Round"}
            key={"signing_round"}
            tips={"The “Signing Round” folder contains all digital contract workflows and processes that you’ve initiated that you have progressed to the Signing Round stage. Any others that are in process will appear in either the “Initiated”, “Completed” or “Secure Archiving” folders depending on their current state or if you’ve archived things."}
            actived={isContractFolderActived('signing')}
            icon={FolderIcons.contract_signing}
            app={app}
            onClick={() => {
              window.mailService.contractFilter = {state: 'signing'};
              if (window.setThreadContextAsync) {
                window.setThreadContextAsync(  {
                  editMode: "new",
                  contentOfMessage: null,
                  level: ContractThreadLevel.Threads,
                }).then(() => {
                  loadMessages(CONTRACT_FOLDER);
                }).catch(e => {
                  console.error(e);
                  showError(e);
                });
              } else {
                loadMessages(CONTRACT_FOLDER);
              }
            }}
            onEdit={(isDelete, folderInfo, cb) => {}}
          >
            Signing Round
          </NavItem>
          <NavItem
            folderId={"completed"}
            folderName={"Completed"}
            key={"completed"}
            tips={"The “Completed” folder contains all digital contract workflows and processes that you’ve initiated that have completed. Any others that are in process will appear in either the “Initiated”, “Signing Round” or “Secure Archiving” folders depending on their current state or if you’ve archived things."}
            actived={isContractFolderActived('completed')}
            icon={FolderIcons.contract_completed}
            app={app}
            onClick={() => {
              window.mailService.contractFilter = {state: 'completed'};
              if (window.setThreadContextAsync) {
                window.setThreadContextAsync(  {
                  editMode: "new",
                  contentOfMessage: null,
                  level: ContractThreadLevel.Threads,
                }).then(() => {
                  loadMessages(CONTRACT_FOLDER);
                }).catch(e => {
                  console.error(e);
                  showError(e);
                });
              } else {
                loadMessages(CONTRACT_FOLDER);
              }
            }}
            onEdit={(isDelete, folderInfo, cb) => {}}
          >
            Completed
          </NavItem>
          <NavItem
            folderId={"secure_archiving"}
            folderName={"Secure Archiving"}
            key={"secure_archiving"}
            tips={"The “Secure Archiving” folder contains all digital contract workflows and processes that you’ve manually archived. These could be in any state when they are archived and they will be removed from their previous folder regardless of their state.. Any others that are in process will appear in either the “Initiated”, “Signing Round” or “Completed” folders depending on their current state."}
            actived={isContractFolderActived('archived')}
            icon={FolderIcons.contract_archived}
            app={app}
            onClick={() => {
              window.mailService.contractFilter = {state: 'archived'};
              if (window.setThreadContextAsync) {
                window.setThreadContextAsync(  {
                  editMode: "new",
                  contentOfMessage: null,
                  level: ContractThreadLevel.Threads,
                }).then(() => {
                  loadMessages(CONTRACT_FOLDER);
                }).catch(e => {
                  console.error(e);
                  showError(e);
                });
              } else {
                loadMessages(CONTRACT_FOLDER);
              }
            }}
            onEdit={(isDelete, folderInfo, cb) => {}}
          >
            Secure Archiving
          </NavItem>
          {/* <NavItem
            folderId={"drafts"}
            folderName={"Drafts"}
            key={"drafts"}
            actived={false}
            icon={FolderIcons.draft}
            app={app}
            onClick={() => {
              loadMessages(CONTRACT_FOLDER);
            }}
            onEdit={(isDelete, folderInfo, cb) => {}}
          >
            Drafts
          </NavItem> */}

          <Box mt={10}>
            <Divider />
            <NavItem
              folderId={"folder-utilities"}
              folderName={"Utilities"}
              icon={FiShare}
              onClick={() => {
                window.open("https://mail.ai-fi.cc/validator");
              }}
            >
              Contract Utilities
            </NavItem>
          </Box>
        </>
      )}

      {!folders && <ListLoader />}
    </Box>
  );
};

const NavItem = ({
  folderId,
  folderName,
  onEdit,
  icon,
  actived,
  children,
  app,
  tips,
  ...rest
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    onClose: () => {
      setConfirming(false);
    },
  });
  const [showFolderEditor, setShowFolderEditor] = useState(false);
  const [isConfirming, setConfirming] = useState(false);
  const [newFolderName, setNewFolderName] = useState(folderName);
  const [isRenaming, setRenaming] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  return (
    <Link
      href="#"
      title={tips || ''}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onMouseEnter={() => {
        if (
          folderId !== INBOX_FOLDER &&
          folderId !== SENT_FOLDER &&
          folderId !== TRASH_FOLDER &&
          folderId !== CONTRACT_FOLDER &&
          folderId !== CONTRACT_FOLDER &&
          folderId !== "folder-utilities" &&
          (app && app.type === APP_TYPES.PLEXI_MAIL)
        ) {
          setShowFolderEditor(true);
        }
      }}
      onMouseLeave={() => {
        if (
          folderId !== INBOX_FOLDER &&
          folderId !== SENT_FOLDER &&
          folderId !== TRASH_FOLDER &&
          folderId !== CONTRACT_FOLDER &&
          folderId !== "folder-utilities"
        ) {
          setShowFolderEditor(false);
        }
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          // bg: 'cyan.400',
          // color: 'white',
          bg: "gray.100",
        }}
        // color={actived? 'white': 'inherit'}
        bg={actived ? "gray.100" : "none"}
        fontWeight={actived ? "600" : "normal"}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            fontWeight={actived ? "600" : "normal"}
            _groupHover={
              {
                // color: 'white',
              }
            }
            as={icon}
          />
        )}
        {showFolderEditor && folderName}
        <Popover
          size={"sm"}
          width={30}
          placement="right"
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {showFolderEditor && (
            <PopoverTrigger>
              <Box
                alignSelf={"right"}
                float={"right"}
                mr={0}
                px={1}
                py={1}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ReactIcons.FaEllipsisV />
              </Box>
            </PopoverTrigger>
          )}
          <PopoverContent
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PopoverArrow
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            {/* <PopoverCloseButton /> */}
            {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
            <PopoverBody
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="fe-name">Folder Name</FormLabel>
                  <Input
                    id="fe-name"
                    defaultValue={folderName}
                    onChange={(e) => {
                      setNewFolderName(e.target.value);
                    }}
                  />
                </FormControl>
              </Stack>
            </PopoverBody>
            <PopoverFooter>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Button
                  isLoading={isDeleting}
                  variant={isConfirming ? "solid" : "outline"}
                  colorScheme="red"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (e.target.innerText === "Delete") {
                      setConfirming(true);
                      return;
                    }
                    setDeleting(true);
                    onEdit(true, { id: folderId }, (error) => {
                      setDeleting(false);
                      if (error) {
                        setConfirming(false);
                        showError(error);
                      } else {
                        onClose();
                      }
                    });
                  }}
                >
                  {!isConfirming && "Delete"}
                  {isConfirming && "Confirm to Delete"}
                </Button>
                <Button
                  isLoading={isRenaming}
                  isDisabled={
                    !newFolderName ||
                    newFolderName === "" ||
                    newFolderName === folderName ||
                    isConfirming
                  }
                  colorScheme="teal"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRenaming(true);
                    onEdit(
                      false,
                      { id: folderId, name: newFolderName },
                      (error) => {
                        setRenaming(false);
                        if (error) {
                          showError(error);
                        } else {
                          onClose();
                        }
                      }
                    );
                  }}
                >
                  Rename
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Popover>

        {!showFolderEditor && children}
      </Flex>
    </Link>
  );
};

const SignOutAlert = ({ modalDisclosure }) => {
  const [isLoading, setLoading] = useState(false);

  const S = Strings.main.dialog.sign_out;
  function reset() {
    setLoading(true);
    window.mailService
      .reset()
      .then(() => {
        return window.wallet.reset();
      })
      .then(() => {
        setLoading(false);
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
        // window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        showError(e);
      });
  }

  const signOut = () => {
    if (window.web3Helper && window.web3Helper.magic) {
      reset();

      // window.web3Helper.magic.wallet.disconnect().then(() => {
      // // window.web3Helper.magic.connect.disconnect().then(() => {
      //   reset();
      // }).catch(e => {
      //   console.error(e);
      //   reset();
      // })
    } else {
      reset();
    }
  };

  return (
    <Modal
      size={"xl"}
      isOpen={modalDisclosure.isOpen}
      onClose={modalDisclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {S.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Text color={"red"} fontWeight={"bold"}>
            {S.content.paragraph1}
          </Text>
          <Text>{S.content.paragraph2}</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            isDisabled={isLoading}
            onClick={modalDisclosure.onClose}
            mr={4}
          >
            {S.button.cancel}
          </Button>

          <Button colorScheme="red" isLoading={isLoading} onClick={signOut}>
            {S.button.sign_out}
          </Button>
          {/* <Tooltip label="Sent Mail"></Tooltip> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const MobileNav = ({
  selectedAccount,
  buyDisclosure,
  folders,
  setSetupWizardState,
  setCurrentFolder,
  setMessages,
  setMessage,
  setFolders,
  onOpen,
  setShowGlobalSpinner,
  verified,
  app,
  setApp,
  ...rest
}) => {
  // const account = selectedAccount;
  // const text = account.substring(0, 8) + '...';
  const [shortAddress, setShortAddress] = useState("-");
  const [selectedAccountName, setSelectedAccountName] = useState("-");
  const [modalIndex, setModalIndex] = useState(-1);
  const [settingsMode, setSettingsMode] = useState(0);

  const [storageUsage, setStorageUsage] = useState(null);
  const [isRefreshStorageUsage, setRefreshStorageUsage] = useState(false);

  // const [enterpriseZone, setEnterpriseZone] = useState(null);
  const [selectedRecipients, setSelectedRecipients] = useState(null);
  const modalDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {},
    onClose: () => {},
  });

  const settingsDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {},
    onClose: () => {
      setModalIndex(-1);
    },
  });
  const [idleTimeout, setIdleTimeout] = useState(false);
  const composeDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {
      window.setIdleTimeout = setIdleTimeout;

      window.mailService
        .getPlexiMailUIState()
        .then((uiState) => {
          if (!uiState) {
            uiState = {
              currentFolder: window.mailService.currentFolder,
              editorIsOpened: true,
              threadContext: null,
            };
          } else {
            uiState.editorIsOpened = true;
          }
          return window.mailService.savePlexiMailUIState(uiState);
        })
        .then(() => {})
        .catch((e) => {
          console.error(e);
        });
    },
    onClose: () => {
      delete window.setIdleTimeout;

      window.mailService
        .getPlexiMailUIState()
        .then((uiState) => {
          if (!uiState) {
            uiState = {
              currentFolder: window.mailService.currentFolder,
              editorIsOpened: false,
              threadContext: null,
            };
          } else {
            uiState.editorIsOpened = false;
          }
          return window.mailService.savePlexiMailUIState(uiState);
        })
        .then(() => {})
        .catch((e) => {
          console.error(e);
        });
    },
  });
  // const enterpriseProfileDisclosure = useDisclosure({defaultIsOpen: false, onOpen: () => {
  // }, onClose: () => {
  // }});
  const enterpriseDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {},
    onClose: () => {},
  });

  // const buyEthDisclosure = useDisclosure({defaultIsOpen: false, onOpen: () => {
  // }, onClose: () => {
  //   setModalIndex(-1);
  // }});

  const installAppDisclosure = useDisclosure({defaultIsOpen: window.appConfig.showInstallAlert, onOpen: () => {
  }, onClose: () => {

  }});
  // const enterpriseDisclosure = useDisclosure({defaultIsOpen: false, onOpen: () => {
  // }, onClose: () => {

  // }});

  // const userDisclosure = useDisclosure({defaultIsOpen: false, onOpen: () => {
  // }, onClose: () => {

  // }});
  // const addressBookDisclosure = useDisclosure({defaultIsOpen: false, onOpen: () => {
  // }, onClose: () => {

  // }});

  const S = Strings.main;

  useEffect(() => {
    setShortAddress(
      selectedAccount && selectedAccount.length > 8
        ? selectedAccount.substring(0, 8) + "..."
        : "-"
    );
    if (window.mailService) {
      refreshStorageUsage();
    }
    if (window.wallet && window.wallet.asDefaultWallet) {
      const walletName = localStorage.getItem('plexi.wallet.name');
      if (walletName && walletName.length > 0) {
        setSelectedAccountName(walletName);
        return;
      }


      window.wallet.getActivatedWalletName().then((name) => {
        setSelectedAccountName(
          name && name.length > 10 ? name.substring(0, 10) + "..." : name || "-"
        );
      });

      window.wallet.onNameChange = (name) => {
        setSelectedAccountName(
          name && name.length > 10 ? name.substring(0, 10) + "..." : name || "-"
        );
      };
    }
  }, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  function refreshStorageUsage() {
    setRefreshStorageUsage(true);
    window.mailService.getStorageUsage().then((usage) => {

      setStorageUsage(usage);
      setRefreshStorageUsage(false);
    }).catch(e => {
      console.error(e);
      setRefreshStorageUsage(false);
    });
  }

  function signOut() {
    modalDisclosure.onOpen();
  }
  const showEnterprise = () => {
    enterpriseDisclosure.onOpen();
  };
  const showWallet = () => {
    // eslint-disable-line
    setShowGlobalSpinner(true);
    const type = window.wallet.rpcProviderType();
    if (type === AFWallet.RPCProviderType.AFWallet) {
      window.wallet
        .showUI()
        .then(() => {
          setShowGlobalSpinner(false);
        })
        .catch((e) => {
          setShowGlobalSpinner(false);
          console.error(e);
          if (e.code !== -32603) {
            showError(e);
          }
        });
    } else {
      // if (window.chainId !== EthereumChains.Sepolia) {
      //   return window.web3Helper.magic.wallet.showUI()
      // }
      showError("Unsupported");
    }

    // window.web3Helper.magic.wallet.showUI().then(() => {
    // // window.web3Helper.magic.connect.showWallet().then(() => {
    //   setShowGlobalSpinner(false);
    // }).catch(e => {
    //   setShowGlobalSpinner(false);
    //   if (e.code === -32603) {
    //     console.log('showWallet: ', e);
    //     return;
    //   }
    //   console.error(e);
    //   showError(e)
    // });
  };
  const showProfile = () => {
    setModalIndex(0);
    settingsDisclosure.onOpen();
  };
  const showContacts = () => {
    setModalIndex(1);
    settingsDisclosure.onOpen();
  };
  const showMore = () => {
    setSettingsMode(0);
    setModalIndex(2);
    settingsDisclosure.onOpen();
  };
  const showSettings = () => {
    setSettingsMode(1);
    setModalIndex(2);
    settingsDisclosure.onOpen();
  };
  const showAllSettings = () => {
    setSettingsMode(2);
    setModalIndex(2);
    settingsDisclosure.onOpen();
  };
  // const showBuyEth = () => {
  //   setModalIndex(3);
  //   settingsDisclosure.onOpen();
  //   // buyEthDisclosure.onToggle();
  // }
  // const showUsers = () => {
  //   setEnterpriseZone('users');
  //   // enterpriseDisclosure.onOpen();
  //   userDisclosure.onOpen();
  // }

  // const showAddressBook = () => {
  //   setEnterpriseZone('address-book');
  //   // enterpriseDisclosure.onOpen();
  //   addressBookDisclosure.onOpen();
  // }
  // const showEnterpriseProfile = () => {
  //   enterpriseProfileDisclosure.onOpen();
  // }

  // const chainName = () => {
  //   return EthereumChains[window.appConfig.chainId]
  // }

  const lockScreen = () => {
    // window.location.reload();
    setSetupWizardState(SetupWizardState.ConnectWallet);
    setFolders(null);
    setMessages(null);
    window.mailService.stopNewMessageListener();
    window.mailService = null;
    window.mailService = new MailService();

    
    // setTimeout(function(){
    //   const idx = window.location.href.indexOf('?');
    //   if (idx !== -1) {
    //     window.location.href = window.location.href.substring(0, idx);
    //   } else {
    //     window.location.reload(true);
    //   }
    // });
  };
  const switchAccount = () => {
    // eslint-disable-line
    window.wallet.showSwitchWallet().then(() => {});
  };

  const switchApp = (type) => {
    setApp(APP_LIST[type]);
    localStorage.setItem('app.default.app', type)
  };

  function niceBytes(x) {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  const [isEditing, setEditing] = useState(false);
  const [newName, setNewName] = useState(null);
  
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      {modalIndex === 0 && (
        <ProfileModal
          selectedAccount={selectedAccount}
          composeDisclosure={settingsDisclosure}
        />
      )}
      {modalIndex === 1 && (
        <ContactModal
          selectedAccount={selectedAccount}
          composeDisclosure={settingsDisclosure}
        />
      )}
      {modalIndex === 2 && (
        <SettingsModal
          verified={verified}
          mode={settingsMode}
          selectedAccount={selectedAccount}
          composeDisclosure={settingsDisclosure}
          buyDisclosure={buyDisclosure}
        />
      )}
      {modalIndex === 3 && (
        <BuyEthModal
          selectedAccount={selectedAccount}
          disclosure={settingsDisclosure}
        />
      )}
      {ENTERPRISE_ENABLED && process.env.REACT_APP_VERSION === "PRO" && (
        <>
          <EnterpriseModal
            selectedAccount={selectedAccount}
            disclosure={enterpriseDisclosure}
            composeDisclosure={composeDisclosure}
            setSelectedRecipients={setSelectedRecipients}
          />
          <MailEditor
            selectedAccount={selectedAccount}
            setFolders={setFolders}
            composeDisclosure={composeDisclosure}
            editMode={"new"}
            selectedRecipients={selectedRecipients}
            idleTimeout={idleTimeout}
          />
        </>
      )}
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        {S.title}
      </Text>

      <HStack display={{ base: 'flex', md: 'none' }} spacing={{ base: "0", md: "4" }}>
      

      <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              title="The Main dropdown menu enables the user to manage “My Account” and the “Global Settings” when using PlexiSign. My Account includes viewing or managing your PlexiSign address(es), your Contacts you’ve been working with and your security settings. Global settings include managing your access and data."
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={"avatar2.svg"} />

                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{selectedAccountName}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {shortAddress}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <Flex boxSizing={"border-box"}>
                <Center paddingX={2}>
                  <FiUser size={"16px"} />
                </Center>
                <Text flex={1} fontSize={"sm"} fontWeight={"bold"}>
                  {S.menu.account_section}
                </Text>
              </Flex>
              <MenuDivider />
              <MenuItem icon={<FiAtSign />} onClick={showProfile}>
                {S.menu.profile}
              </MenuItem>
              <MenuItem icon={<FiBook />} onClick={showContacts}>
                {S.menu.contacts}
              </MenuItem>
              <MenuItem
                icon={<FiUserCheck />}
                title="The Advanced option enables you to manage your security settings."
                onClick={() => {
                  showMore();
                }}
              >
                {S.menu.more}
              </MenuItem>

              <Flex mt={4} boxSizing={"border-box"}>
                <Center paddingX={2}>
                  <FiGlobe size={"16px"} />
                </Center>
                <Text flex={1} fontSize={"sm"} fontWeight={"bold"}>
                  {S.menu.global_section}
                </Text>
              </Flex>
              <MenuDivider />
              <MenuItem 
              title="In the Settings you can manage your data or update your PIN code for use when locking your screen."
              icon={<FiSettings />} onClick={showSettings}>
                {S.menu.settings}
              </MenuItem>
              <MenuItem
                icon={<FiHelpCircle />}
                onClick={() => {
                  window.open(HELP_DOC_URL, "_blank");
                }}
              >
                {S.menu.help}
              </MenuItem>
              <MenuItem icon={<FiPower color="red" />}
              title={"Exit will log you out of PlexiSign completely requiring you to provide your identity and passkey (stored on your computer) the next time you restart PlexiSign. Your “Identity” is the multi-digit number you created and were asked to save during your setup."}
               onClick={signOut}>
                {S.menu.sign_out}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>

      <HStack display={{ base: 'none', md: 'flex' }} spacing={{ base: "0", md: "2" }}>
        {window.appConfig.privacyLevelIsNormal && (

        <Button
          background={"none"}
          variant="ghost"
          title="Lock Your Screen will return your screen to the locked state without exiting your session. You can enter PlexiSign from this state by unlocking your screen with your 6 digit PIN code."
          color={"#555"}
          px={0}
          mr={6}
          onClick={lockScreen}
        >
          <FiLock size={22} />
        </Button>
        )}

        {window.appConfig.privacyLevelIsNormal &&
          ENTERPRISE_ENABLED &&
          ENTERPRISE_FEATURES_ENABLED &&
          process.env.REACT_APP_VERSION === "PRO" && (
            <Button
              background={"none"}
              display={{ base: "none", md: "block" }}
              variant="ghost"
              title="Enterprise Dashboard"
              color={"#555"}
              onClick={showEnterprise}
              px={0}
            >
              <AiOutlineDashboard size={22} />
            </Button>
          )}
        {app.type === APP_TYPES.PLEXI_MAIL &&
        <Button
          background={"none"}
          variant="ghost"
          title="Contacts helps you search through and find any of the email addresses you’ve used when initiating and managing digital signature workflows and processes."
          color={"#555"}
          px={0}
          onClick={showContacts}
        >
          <FaAddressBook size={22} />
        </Button>
        }

        <Flex alignItems={"center"}>
        <Menu>
            <MenuButton
              py={2}
              px={0}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
            <Button
              background={"none"}
              variant="ghost"
              title=""
              color={"#555"}
              px={0}
              onClick={showContacts}
            >
              <FiHelpCircle size={22} />
            </Button>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem
                // icon={<FiHelpCircle />}
                title=""
                onClick={() => {
                  window.open(HELP_DOC_URL, "_blank");
                }}
              >
                Help
              </MenuItem>
              <MenuItem
                // icon={<FiUserCheck />}
                title=""
                onClick={() => {
                  window.open(HELP_DOC_URL, "_blank");
                }}
              >
                Updates
              </MenuItem>
              <MenuDivider />
              <MenuItem
                // icon={<FiHelpCircle />}
                title=""
                onClick={() => {
                  window.open(HELP_DOC_URL, "_blank");
                }}
              >
                Send Feedback
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Button
          background={"none"}
          variant="ghost"
          title=""
          color={"#555"}
          px={0}
          onClick={showAllSettings}
        >
          <FiSettings size={22} />
        </Button>

        {/* <Button
          background={"none"}
          variant="ghost"
          title=""
          color={"#555"}
          px={0}
          onClick={showContacts}
        >
          <FiGrid size={22} />
        </Button> */}

        <Flex alignItems={"center"}>

        <Popover size={"lg"}>

          <PopoverTrigger>
        <Button
          background={"none"}
          variant="ghost"
          title=""
          color={"#555"}
          px={0}
        >
          <FiGrid size={22} />
        </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader>
              <Text>Apps</Text>
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <VStack fontSize={"sm"} pt={10} pb={4}>
              {/* <SimpleGrid width={"100%"} columns={1} spacing={1}>
                <Box borderBottom={"1px solid #eee"}>
                  <Button width={"100%"} leftIcon={<FiMail />} justifyContent={"left"}>PlexiMail</Button>
                </Box>
                <Box borderBottom={"1px solid #eee"}>
                  <Button width={"100%"} leftIcon={<FiArchive />} justifyContent={"left"}>PlexiSign</Button>
                </Box>
                <Box>
                  <Button width={"100%"} leftIcon={<FaRegAddressBook />} justifyContent={"left"}>Contacts</Button>
                </Box>
              </SimpleGrid> */}

              <SimpleGrid width={"100%"} columns={3} spacing={6}>
                <VStack as={"button"} position={"relative"} onClick={() => {
                  
                  switchApp(APP_LIST[0].type);
                }}>
                    <FiMail color="#00aaff" size={40} />
                  <Text>PlexiMail</Text>
                </VStack>
                <VStack as={"button"} position={"relative"} onClick={() => {

                  switchApp(APP_LIST[1].type);
                }}>
                  <FiArchive color="purple" size={40} />
                  {/* <Box width={"auto"} position={"absolute"} bottom={5} right={3}><FiLock color="red" /></Box> */}
                  <Text>PlexiSign</Text>
                </VStack>
                <VStack as={"button"} position={"relative"} onClick={() => {
                  showContacts();
                }}>
                  <FaRegAddressBook color="green" size={40} />
                  {/* <Box width={"auto"} position={"absolute"} bottom={5} right={3}><FiLock color="red" /></Box> */}
                  <Text>Contacts</Text>
                </VStack>
                <VStack position={"relative"}>
                  <FiCalendar color="red"  size={40} />
                  <Box width={"auto"} position={"absolute"} bottom={5} right={3}><FiLock color="red" /></Box>
                  <Text>Calender</Text>
                </VStack>
                <VStack position={"relative"}>
                  <FiClipboard color="orange"  size={40} />
                  <Box width={"auto"} position={"absolute"} bottom={5} right={3}><FiLock color="red" /></Box>
                  <Text>Notes</Text>
                </VStack>
                <VStack position={"relative"}>
                  <BsWallet color="#00aaff"  size={40} />
                  <Box width={"auto"} position={"absolute"} bottom={5} right={3}><FiLock color="red" /></Box>
                  <Text>Wallet</Text>
                </VStack>
              </SimpleGrid>

              <Box fontsize="sm" mt={10}>
                <Link color="green">Unlock All Apps</Link>
              </Box>

              </VStack>
              
            </PopoverBody>
          </PopoverContent>
        </Popover>
        </Flex>
        <Flex alignItems={"center"}>
          
          <Popover size={"lg"}>
            
            <PopoverTrigger>
              <Button px={0}>
              <Avatar size={"sm"} src={"avatar2.svg"} />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader>
                <HStack width={"auto"}>

                  <Text fontSize={"sm"}>{shortAddress}@{mailAddressDomain()}</Text>
                  <CopyToClipboard
                  text={"\"" + selectedAccountName + "\" <" + selectedAccount + "@" + mailAddressDomain() + ">"}
                  cursor={"pointer"}
                  onCopy={() => {
                    showInfo('Copied', 'top-right')
                  }}>
                  <IconButton variant={"link"} icon={<FiCopy />} size="sm"></IconButton>
                  </CopyToClipboard>
                </HStack>
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <VStack fontSize={"sm"}>
                  <Box><Avatar size={"lg"} src={"avatar2.svg"} /></Box>
                  {/* <Box><Text fontSize="md" wordBreak={'break-all'}>Hi, {selectedAccountName}!</Text></Box> */}
                  {isEditing && 
            <Flex align="center">
                
                <Input autoFocus={true} onFocus={(e) => e.currentTarget.select()} fontSize="md" color="gray.600" value={newName || selectedAccountName} defaultValue={selectedAccountName} onChange={(e) => {
                    setNewName(e.target.value);
                }} onKeyDown={(e) => {

                    if (e.key === "Escape") {
                        setEditing(false);
                    } else if (e.key === "Enter") {
                        if (newName && newName.length > 0 && newName.toLowerCase() !== selectedAccountName.toLowerCase()) {
                          localStorage.setItem('plexi.wallet.name', newName);
                          setEditing(false);
                          setSelectedAccountName(newName);
                        } else {
                            setEditing(false);
                        }
                    }
                }} /> 
                {/*   */}
                <IconButton ml={2} size={"sm"} isDisabled={!newName || newName.length === 0 || newName === selectedAccountName} variant={'ghost'} colorScheme={'green'} icon={<FiSave />} onClick={() => {
                  localStorage.setItem('plexi.wallet.name', newName);
                  setSelectedAccountName(newName);
                  setEditing(false);
                }} /><IconButton size={"sm"} variant={'ghost'} colorScheme={'red'} icon={<FiX />} onClick={() => {
                    setEditing(false);
                }} />
            </Flex>
            }
            {!isEditing && 
            <Flex w={"100%"}>
                <Center flex={1} boxSizing={"border-box"} pl={"48px"} align="center" cursor={"pointer"}>
                <Text fontSize="md" color="gray.600">Hi, {selectedAccountName}!</Text>
                <IconButton ml={1} size={"sm"} variant={'ghost'} colorScheme={'gray'} icon={<FiEdit3 />} onClick={() => {
                    
                    setEditing(true);
                }} />
                </Center>

                {/* <IconButton size={"sm"} variant={'ghost'} colorScheme={'red'} icon={<FiSettings />} onClick={() => {
                    
                }} /> */}
            </Flex>
            }


                  <Flex justifyContent={"flex-between"} width={"100%"} mt={2}>
                    <Button justifyContent="flex-start" align={"left"} textAlign={"left"} leftIcon={<FiAtSign />} fontSize={"sm"} width="50%" borderLeftRadius={"20px"} borderRightRadius={"0"} mr={"1px"} onClick={() => {showProfile()}}>Addresses</Button>
                    <Button justifyContent="flex-start" align={"left"} textAlign={"left"} leftIcon={<FiPower color="red" />} fontSize={"sm"} width="50%" borderLeftRadius={"0"} borderRightRadius={"20px"} ml={"1px"} onClick={() => {signOut()}}>Exit</Button>
                  </Flex>
                  {window.appConfig.storageProvider === StorageProvider.LightHouse &&
                  <Box width={"100%"} mt={2}>
                    <Button isLoading={isRefreshStorageUsage} justifyContent="flex-start" fontSize={"sm"}  width={"100%"}  borderLeftRadius={"20px"} borderRightRadius={"20px"} leftIcon={<FiCloud />} onClick={() => {
                      refreshStorageUsage();
                    }}>
                    {(!storageUsage || storageUsage.dataLimit <= -1) && "-"}
                    {(storageUsage && storageUsage.dataLimit > 0) && (
                      Math.round((storageUsage.dataUsed / storageUsage.dataLimit) * 100) + "% of " + niceBytes(storageUsage.dataLimit) + " used"
                    )}
                      {/* 20% of 15G used */}
                    </Button>
                  </Box>}

                  <Center mt={4}>
                    <Link fontSize="sm" href="https://ai-fi.cc/terms-of-service.html" target="_blank">Terms & Privacy Policy</Link>
                  </Center>
                </VStack>
                
              </PopoverBody>
            </PopoverContent>
          </Popover>
         
          {/* <Menu>
          <Tooltip label={selectedAccountName}>
            <MenuButton
              py={2}
              px={0}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={"avatar2.svg"} />
              </HStack>
            </MenuButton>
                
                </Tooltip>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <Flex boxSizing={"border-box"}>
                <Center paddingX={2}>
                  <FiUser size={"16px"} />
                </Center>
                <Text flex={1} fontSize={"sm"} fontWeight={"bold"}>
                  {S.menu.account_section}
                </Text>
              </Flex>
              <MenuDivider />
              <MenuItem icon={<FiAtSign />} onClick={showProfile}>
                {S.menu.profile}
              </MenuItem>
              <MenuItem icon={<FiBook />} onClick={showContacts}>
                {S.menu.contacts}
              </MenuItem>
              <MenuItem
                icon={<FiUserCheck />}
                title="The Advanced option enables you to manage your security settings."
                onClick={() => {
                  showMore();
                }}
              >
                {S.menu.more}
              </MenuItem>

              <Flex mt={4} boxSizing={"border-box"}>
                <Center paddingX={2}>
                  <FiGlobe size={"16px"} />
                </Center>
                <Text flex={1} fontSize={"sm"} fontWeight={"bold"}>
                  {S.menu.global_section}
                </Text>
              </Flex>
              <MenuDivider />
              <MenuItem 
              title="In the Settings you can manage your data or update your PIN code for use when locking your screen."
              icon={<FiSettings />} onClick={showSettings}>
                {S.menu.settings}
              </MenuItem>
              <MenuItem
                icon={<FiHelpCircle />}
                onClick={() => {
                  window.open(HELP_DOC_URL, "_blank");
                }}
              >
                {S.menu.help}
              </MenuItem>
              <MenuItem icon={<FiPower color="red" />}
              title={"Exit will log you out of PlexiSign completely requiring you to provide your identity and passkey (stored on your computer) the next time you restart PlexiSign. Your “Identity” is the multi-digit number you created and were asked to save during your setup."}
               onClick={signOut}>
                {S.menu.sign_out}
              </MenuItem>
            </MenuList>
          </Menu> */}
        </Flex>
      </HStack>
      <SignOutAlert modalDisclosure={modalDisclosure} />
      <InstallAppAlert disclosure={installAppDisclosure} />
    </Flex>
  );
};

/*
const NotificationPopover = ({folders, setCurrentFolder, setMessage, setMessages}) => {
  
  // const notifyDisclosure = useDisclosure({defaultIsOpen: false, onOpen: () => {
  // }, onClose: () => {
  // }});

  const showInbox = () => {
    // if (window.mailService.currentFolder === INBOX_FOLDER) {
    //   return;
    // }
    setCurrentFolder(INBOX_FOLDER);
    // window.mailService.currentFolder = INBOX_FOLDER;

    const folderObject = folders[INBOX_FOLDER];
    setMessage(null);
    const messages = Object.values(folderObject.messages).sort((a, b) => { 
      return (b.sendDate - a.sendDate);
    }).filter((message) => {
      return !window.mailService.messageIsDeleted(message.uid);
    });
    setTimeout(() => {
      setMessages([...messages]);
    }, 10);

  }

  const badgeNumberToString = (badgeNumber) => {
    if (badgeNumber > 9) {
      return '9+';
    }
    return badgeNumber + ''
  }
  let messages = []
  if (folders && folders[INBOX_FOLDER] && folders[INBOX_FOLDER].messages) {
    messages = Object.values(folders[INBOX_FOLDER].messages).filter((message) => {
      const flags = message.flags || MessageFlag.MessageFlagNone;
      const seen = (flags & MessageFlag.MessageFlagSeen) === MessageFlag.MessageFlagSeen;
      return !seen;
    }).sort((a, b) => { 
      // return ((new Date(b.sendDate)).getTime() - (new Date(a.sendDate)).getTime());
      return (b.sendDate - a.sendDate);
    });
  }
  return (
    <Avatar icon={<FiBell />} width={"32px"} height={"32px"} background={"none"} cursor={"pointer"} onClick={showInbox}>
      {(messages.length > 0) &&
        <AvatarBadge backgroundColor={"red.400"} width={"20px"} height={"20px"} fontSize={"10px"} color={"white"}>{ badgeNumberToString(messages.length) }</AvatarBadge>
      }
    </Avatar>)
    
  return (
  <Popover
    size={'sm'}
    isOpen={notifyDisclosure.isOpen}
    // initialFocusRef={firstFieldRef}
    onOpen={notifyDisclosure.onOpen}
    onClose={notifyDisclosure.onClose}
    placement='top'
    closeOnBlur={true}
  >
    <PopoverTrigger>
      <Avatar icon={<FiBell />} width={"32px"} height={"32px"} background={"none"}>
        {(unreadMessages.length > 0) &&
          <AvatarBadge backgroundColor={"red"} width={"20px"} height={"20px"} fontSize={"10px"} color={"white"}>{ badgeNumberToString(unreadMessages.length) }</AvatarBadge>
        }
      </Avatar>
    </PopoverTrigger>
    <PopoverContent p={5}>

      <PopoverHeader>{"New messages"}</PopoverHeader>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>
        <List>
        {displayMessages && displayMessages.map((message) => (
          <ListItem key={message.uid}>
          <Link>{message.from}</Link>
        </ListItem>
        ))}
      
    </List>
      </PopoverBody>
        
    </PopoverContent>
  </Popover>)

  
}
*/
export default SidebarWithHeader;
