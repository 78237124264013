/*
{
    chakra-ui-color-mode: "light"
    mail.folders-sign-key: "CAESQDq/dZ1UWDJmbWyW/WY3VHlskleHmLHA+3YeI5bdc2qNa2cPIyqbMsZhEIRjL36HiWWxo943FaNcQZZAZaXOPjw="
    mail.prekeys-sign-key: "CAESQE+Np11BRtZPXBH/DGw1oRV7KO9RWJSAQ3rGWo514RBiw9gR+rgQG7JEBfFkQuN66MncTe5eRk+etHflENB6WlE="
    mail.signal-context-sign-key: "CAESQEwmM2hToqbIZqXqVw6YawjXCliAtLW2cLTsXNGywbP3xZqnqM/uuQ6Y8UHeN6DVnvxkWXPdDpfN+5XIPhK0hfU="
    web3.storage-token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMTMzNTJBNzcwYkE4M2M2YUU4ZTQwOWIyMTU4NGY1YjJhZmI3OGMiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NTUyNjUzOTcwNDAsIm5hbWUiOiJFeGFtcGxlIn0.o89ztC7cQ3lyvqgI77phbL7Vz3_pZK0IbKIA-clZ-X4"
}
*/
import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Link,
  FormLabel,
  HStack,
  Spinner,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import {
  MnemonicBackupMethod,
  RecoveryState,
  SetupWizardState,
  StorageProvider,
  Web3StorageAccessMethod,
} from "../utils/Types";
import { showError } from "../common/errors";
import Strings from "../config/Strings";
import { TutorialModalLink } from "./TutorialModalTrigger";
import {
  BACKUP_FOLDER,
  RECOVER_WEB3_CONFIG_AUTOMATICALLY,
  CONFIG_PLEXIMAIL_AUTOMATICALLY,
  mailAddressDomain,
} from "../common/constants";

// import W3UpWeb3Storage from "./w3ui/W3UpWeb3Storage";

import MailAddressUtils from "../utils/MailAddressUtils";
import { ConfirmationCloseButton } from "./ConfirmationButton";

// import lighthouse from "@lighthouse-web3/sdk";
// const w = lighthouse.createWallet('this is a book');

function Web3StorageProviderSelector({
  setSetupWizardState,
  setupWizardState,
  setStorageProvider,
}) {
  const lighthouseClicked = () => {
    setStorageProvider(StorageProvider.LightHouse);
  };
  const web3StorageClicked = () => {
    window.appConfig.web3StorageAccessMethod = Web3StorageAccessMethod.Private;
    setStorageProvider(StorageProvider.Web3Storage);
  };
  const sharedWeb3StorageClicked = () => {
    window.appConfig.web3StorageAccessMethod = Web3StorageAccessMethod.Shared;
    setStorageProvider(StorageProvider.Web3Storage);
  };
  const reset = () => {
    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          {/* <Text
            as={"span"}
            bgGradient="linear(to-r, red.400,pink.400)"
            bgClip="text"
          >
            {Strings.web3.w3ui.step} &nbsp;
          </Text> */}
          {/* {Strings.web3.storage_selector.title} */}
          {SetupWizardState.ConfigWeb3Storage === setupWizardState &&
            Strings.web3.storage_selector.title}
          {SetupWizardState.RestoreWeb3Storage === setupWizardState &&
            Strings.web3.storage_selector.recover_title}
        </Heading>

        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.web3.storage_selector.content}
        </Text>
        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {/* {Strings.web3.w3ui.note}&nbsp; */}
          {/* <Link style={{color: '#00aaff'}} onClick={tutorialDisclosure.onOpen()} target={"_blank"}>Learn more</Link> */}
          {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
          {/* <TutorialModalLink
            title={"Learn more"}
            anchor={"tokenized-storage"}
          /> */}
          <Link
            style={{ color: "#00aaff" }}
            href={`https://ai-fi.cc/blog-single.html?article=unbearablelightness#tokenized-storage`}
            target={"_blank"}>
            Learn more
          </Link>
          
        </Text>
      </Stack>
      <Box as={"form"} mt="0px" marginTop={"0px"}>
        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={lighthouseClicked}
        >
          {Strings.web3.storage_selector.lighthouse}
        </Button>
        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={web3StorageClicked}
        >
          {Strings.web3.storage_selector.web3storage}
        </Button>
        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={sharedWeb3StorageClicked}
        >
          {Strings.web3.storage_selector.sharedWeb3storage}
        </Button>

        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgColor={"gray.200"}
          color={"black"}
          onClick={reset}
        >
          {Strings.web3.storage_selector.back}
        </Button>
      </Box>
    </>
  );
}


function Web3StorageLighthouseConfigurator({
  setSetupWizardState,
  setupWizardState,
  selectedAccount,
  setStorageProvider,
}) {

  const [recoveryState, setRecoveryState] = useState(
    RecoveryState.ReadyToRecovery
  );

  const [isInitializing, setInitializing] = useState(false);

  useEffect(() => {

    automaticRestoreIfNeeded()
      .then(() => {})
      .catch((e) => {
        console.error(e);
      });

    if (CONFIG_PLEXIMAIL_AUTOMATICALLY) {
      nextClicked().then(() => {
        console.log('Next Clicked');
      }).catch(e => {
        console.error(e);
        showError(e);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function progress() {
    switch (recoveryState) {
      case RecoveryState.ReadyToRecovery:
        return Strings.web3.lighthouse.import.message.ready;
      case RecoveryState.RecoveryWeb3Storage:
        return Strings.web3.lighthouse.import.message.recover_web3;
      case RecoveryState.RetrieveFolders:
        return Strings.web3.lighthouse.import.message.retrieve_folder;
      case RecoveryState.RecoverySignal:
        return Strings.web3.lighthouse.import.message.recover_signal;
      case RecoveryState.Completed:
        return Strings.web3.lighthouse.import.message.completed;
      default:
        return null;
    }
  }

  async function saveWeb3StorageConfig() {
    try {
      
      await window.mailService.initWeb3StorageClient(
        window.appConfig.lightHouseApiKey
      );

      const res = await window.mailService.initFoldersIfNeeded(
        SetupWizardState.RestoreWeb3Storage === setupWizardState,
        false
      );
      console.log(res);

      if (window.wallet && window.wallet.asDefaultWallet) {
        
      } else {
        const saveLocally =
          window.appConfig.mnemonicBackupMethod ===
          MnemonicBackupMethod.LocalFile;
        await window.mailService.saveCryptonToken(
          selectedAccount,
          window.appConfig.masterKey,
          window.appConfig.mnemonic,
          window.appConfig.lightHouseApiKey,
          true,
          saveLocally
        );
      }
      setSetupWizardState(SetupWizardState.ConfigSignal);
    } catch (e) {
      console.error(e);
      showError(e);
      console.error(e);
    }
  }

  async function doRestoreWeb3StorageConfig() {
    await window.mailService.initWeb3StorageClient(
      window.appConfig.lightHouseApiKey
    );
    setRecoveryState(RecoveryState.RetrieveFolders);
    const folders = await window.mailService.getFolders();

    setRecoveryState(RecoveryState.RecoverySignal);
    const backup = folders[BACKUP_FOLDER];
    Object.keys(backup.localStorage).forEach(function (key) {
      if (key.lastIndexOf("app.private-level") !== -1) {
        return;
      }
      if (!window.appConfig.privacyLevelIsNormal) {
        if (key.lastIndexOf(".app.setup-wizard-state") !== -1) {
          return;
        }
      }
      localStorage.setItem(key, backup.localStorage[key]);
    });

    if (!window.appConfig.privacyLevelIsNormal) {
      window.appConfig.setupWizardState =
        backup.localStorage[
          window.appConfig.recentActiveAccount + ".app.setup-wizard-state"
        ];
    }

    await window.mailService.importIndexedDB(backup.indexedDB);

    await window.mailService.initSignalService(selectedAccount);
    setRecoveryState(RecoveryState.Completed);
    setSetupWizardState(SetupWizardState.WaitForTransactionReceipt);
  }

  async function restoreWeb3StorageConfig() {
    try {
      if (window.mailService.isRecovering) {
        return;
      } else {
        window.mailService.isRecovering = true;
      }

      await doRestoreWeb3StorageConfig();
      window.mailService.isRecovering = false;

    } catch (e) {
      window.mailService.isRecovering = false;
      console.error(e);
      showError(e);
      setInitializing(false);
      console.error(e);
    }
  }

  async function authenticate() {
    try {
      await window.mailService.web3StorageClient.authenticate(window.appConfig.web3StorageApiToken);
    } catch (err) {
      console.error(err);
      console.log("failed to register: ", err.message || err);
      throw err;
    }
  }
  async function automaticRestoreIfNeeded() {
    if (RECOVER_WEB3_CONFIG_AUTOMATICALLY) {
      if (
        SetupWizardState.RestoreWeb3Storage === setupWizardState &&
        window.appConfig.web3StorageApiToken &&
        window.appConfig.web3StorageApiToken.length > 0
      ) {
        if (!window.mailService.isRecovering) {
          try {
            setInitializing(true);
            await authenticate();
            await restoreWeb3StorageConfig();
          } catch (e) {
            showError(e);
          } finally {
            setInitializing(false);
          }
        }
      }
    }
  }
  async function nextClicked() {
    try {
      setInitializing(true);
      await authenticate();

      if (SetupWizardState.ConfigWeb3Storage === setupWizardState) {
        await saveWeb3StorageConfig();
      } else if (SetupWizardState.RestoreWeb3Storage === setupWizardState) {
        await restoreWeb3StorageConfig();
      }
    } catch (e) {
      console.log("failed to register: ", e.message || e);
      showError(e);
    } finally {
      setInitializing(false);
    }
  }

  const reset = () => {
    
    if (SetupWizardState.ConfigWeb3Storage === setupWizardState) {
      setStorageProvider(StorageProvider.Unspecified);
      return
    }
  
    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          {/* {window.wallet &&
            window.wallet.asDefaultWallet &&
            SetupWizardState.ConfigWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.w3ui.step} &nbsp;
              </Text>
            )}
          {window.wallet &&
            window.wallet.asDefaultWallet &&
            SetupWizardState.RestoreWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.w3ui.step} &nbsp;
              </Text>
            )}

          {(!window.wallet || !window.wallet.asDefaultWallet) &&
            SetupWizardState.ConfigWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.config.step} &nbsp;
              </Text>
            )} */}
          {(!window.wallet || !window.wallet.asDefaultWallet) &&
            SetupWizardState.RestoreWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.config.step_restore} &nbsp;
              </Text>
            )}
          {/* {SetupWizardState.ConfigWeb3Storage === setupWizardState &&
            Strings.web3.config.title}
          {SetupWizardState.RestoreWeb3Storage === setupWizardState &&
            Strings.web3.config.recover_title} */}
            {SetupWizardState.ConfigWeb3Storage === setupWizardState &&
            Strings.web3.storage_selector.title}
          {SetupWizardState.RestoreWeb3Storage === setupWizardState &&
            Strings.web3.storage_selector.recover_title}
        </Heading>

        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.web3.lighthouse.content} 
        </Text>
      </Stack>
      <Box as={"form"} mt="0px" marginTop={"0px"}>
        <Stack spacing={1}>
          
          {recoveryState !== RecoveryState.ReadyToRecovery && (
            <HStack>
              <Spinner color={"orange"} size={"sm"} />
              <Text color={"orange"} fontSize={{ base: "sm", sm: "md" }}>
                {progress()}
              </Text>
            </HStack>
          )}

          {isInitializing && (
            <>
              <HStack>
                <Spinner color={"#00aaff"} size={"sm"} />
                <Text
                  color={"#00aaff"}
                  fontWeight={"bold"}
                  fontSize={{ base: "lg", sm: "lg" }}
                >
                  {Strings.web3.lighthouse.auth}
                </Text>
              </HStack>
            </>
          )}

          <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
            {/* {Strings.web3.w3ui.note}&nbsp; */}
            {/* <Link style={{color: '#00aaff'}} onClick={tutorialDisclosure.onOpen()} target={"_blank"}>Learn more</Link> */}
            {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
            {/* <TutorialModalLink
              title={"Learn more"}
              anchor={"tokenized-storage"}
            /> */}
            <Link
              style={{ color: "#00aaff" }}
              href={`https://ai-fi.cc/blog-single.html?article=unbearablelightness#tokenized-storage`}
              target={"_blank"}>
              Learn more
            </Link>
            
          </Text>
          {/* <Link href='https://web3.storage/docs/#quickstart' target={"_blank"}>Apply for your own Web3 Storage space.</Link> */}
        </Stack>
{/*         
        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          isLoading={isInitializing}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={nextClicked}
        >
          {Strings.web3.config.button.next.title}
        </Button>
        
        {window.wallet &&
          window.wallet.asDefaultWallet &&
          SetupWizardState.RestoreWeb3Storage === setupWizardState && (
            <ConfirmationCloseButton
              fontFamily={"heading"}
              mt={4}
              w={"full"}
              bgColor={"gray.200"}
              // bgGradient="linear(to-r, red.400,pink.400)"
              color={"black"}
              // _hover={{
              //     bgGradient: 'linear(to-r, red.400,pink.400)',
              //     boxShadow: 'xl',
              // }}
              isDisabled={isInitializing}
              onClick={() => {
                reset();
              }}
            >
              {Strings.web3.config.button.back.title}
            </ConfirmationCloseButton>
          )}
        {(!window.wallet ||
          !window.wallet.asDefaultWallet ||
          SetupWizardState.RestoreWeb3Storage !== setupWizardState) && (
          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgColor={"gray.200"}
            // bgGradient="linear(to-r, red.400,pink.400)"
            color={"black"}
            // _hover={{
            //     bgGradient: 'linear(to-r, red.400,pink.400)',
            //     boxShadow: 'xl',
            // }}
            disabled={isInitializing}
            onClick={() => {
              reset();
            }}
          >
            {Strings.web3.config.button.back.title}
          </Button>
        )} */}

          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgColor={"gray.200"}
            // bgGradient="linear(to-r, red.400,pink.400)"
            color={"black"}
            // _hover={{
            //     bgGradient: 'linear(to-r, red.400,pink.400)',
            //     boxShadow: 'xl',
            // }}
            isDisabled={isInitializing}
            disabled={isInitializing}
            onClick={nextClicked}
          >
            {"Retry"}
          </Button>
      </Box>
    </>
  );
}


function Web3StorageW3UIConfigurator({
  setSetupWizardState,
  setupWizardState,
  selectedAccount,
  setStorageProvider,
}) {
  const isValidEmail = MailAddressUtils.isValidEmailAddress(
    window.appConfig.web3StorageApiToken || "",
    false
  );

  const [recoveryState, setRecoveryState] = useState(
    RecoveryState.ReadyToRecovery
  );

  const [isInitializing, setInitializing] = useState(false);
  const [isAuthenticating, setAuthenticating] = useState(false);

  const [email, setEmail] = useState(
    isValidEmail ? window.appConfig.web3StorageApiToken : "w3up-shared-storage@ai-fi.cc"
  );

  const [resendMessage, setResentMessage] = useState(null);
  const [createSpaceStep, setCreateSpaceStep] = useState(1);
  
  // const isRecovered = useState(false);
  useEffect(() => {
    /*
        if (SetupWizardState.RestoreWeb3Storage === setupWizardState && (window.appConfig.web3StorageApiToken && window.appConfig.web3StorageApiToken.length > 0)) {
            // await restoreWeb3StorageConfig();
            if (!window.mailService.isRecovering) {
                window.mailService.isRecovering = true;
                doRestoreWeb3StorageConfig().then(() => {
                    window.mailService.isRecovering = false;

                }).catch(e => {
                    window.mailService.isRecovering = false;
                    console.error(e);
                    showError(e);
                    setInitializing(false);
                    console.error(e);
                })
            }
        }
        */

    automaticRestoreIfNeeded()
      .then(() => {})
      .catch((e) => {
        console.error(e);
      });


    if (CONFIG_PLEXIMAIL_AUTOMATICALLY) {
      nextClicked().then(() => {
        console.log('Next Clicked');
      }).catch(e => {
        console.error(e);
        showError(e);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function progress() {
    switch (recoveryState) {
      case RecoveryState.ReadyToRecovery:
        return Strings.web3.import.message.ready;
      case RecoveryState.RecoveryWeb3Storage:
        return Strings.web3.import.message.recover_web3;
      case RecoveryState.RetrieveFolders:
        return Strings.web3.import.message.retrieve_folder;
      case RecoveryState.RecoverySignal:
        return Strings.web3.import.message.recover_signal;
      case RecoveryState.Completed:
        return Strings.web3.import.message.completed;
      default:
        return null;
    }
  }

  async function saveWeb3StorageConfig() {
    try {
      const isValidString = Boolean(email);
      if (!isValidString) {
        return;
      }

      setInitializing(true);
      window.appConfig.web3StorageApiToken = email;

      await window.mailService.initWeb3StorageClient(
        window.appConfig.web3StorageApiToken
      );
      // await window.mailService.initSignalService(selectedAccount);

      const res = await window.mailService.initFoldersIfNeeded(
        SetupWizardState.RestoreWeb3Storage === setupWizardState,
        false
      );
      console.log(res);

      if (window.wallet && window.wallet.asDefaultWallet) {
        //const saveLocally = (window.appConfig.mnemonicBackupMethod === MnemonicBackupMethod.LocalFile);
        //await window.mailService.saveCryptonToken(selectedAccount, window.appConfig.masterKey, "none", window.appConfig.web3StorageApiToken, true, saveLocally);
      } else {
        const saveLocally =
          window.appConfig.mnemonicBackupMethod ===
          MnemonicBackupMethod.LocalFile;
        await window.mailService.saveCryptonToken(
          selectedAccount,
          window.appConfig.masterKey,
          window.appConfig.mnemonic,
          window.appConfig.web3StorageApiToken,
          true,
          saveLocally
        );
      }
      setInitializing(false);
      setSetupWizardState(SetupWizardState.ConfigSignal);
      // setSetupWizardState(SetupWizardState.Completed);
    } catch (e) {
      console.error(e);
      showError(e);
      setInitializing(false);
      console.error(e);
    }
  }

  async function doRestoreWeb3StorageConfig() {
    setInitializing(true);
    await window.mailService.initWeb3StorageClient(
      window.appConfig.web3StorageApiToken
    );
    setRecoveryState(RecoveryState.RetrieveFolders);
    const folders = await window.mailService.getFolders();

    setRecoveryState(RecoveryState.RecoverySignal);
    const backup = folders[BACKUP_FOLDER];
    Object.keys(backup.localStorage).forEach(function (key) {
      if (key.lastIndexOf("app.private-level") !== -1) {
        return;
      }
      if (!window.appConfig.privacyLevelIsNormal) {
        if (key.lastIndexOf(".app.setup-wizard-state") !== -1) {
          return;
        }
      }
      localStorage.setItem(key, backup.localStorage[key]);
    });

    if (!window.appConfig.privacyLevelIsNormal) {
      window.appConfig.setupWizardState =
        backup.localStorage[
          window.appConfig.recentActiveAccount + ".app.setup-wizard-state"
        ];
    }

    await window.mailService.importIndexedDB(backup.indexedDB);

    await window.mailService.initSignalService(selectedAccount);
    setInitializing(false);
    setRecoveryState(RecoveryState.Completed);
    setSetupWizardState(SetupWizardState.WaitForTransactionReceipt);
  }

  async function restoreWeb3StorageConfig() {
    try {
      const isValidString = Boolean(email);
      if (!isValidString) {
        return;
      }
      if (window.mailService.isRecovering) {
        return;
      } else {
        window.mailService.isRecovering = true;
      }
      window.appConfig.web3StorageApiToken = email;

      await doRestoreWeb3StorageConfig();
      window.mailService.isRecovering = false;

      // await window.mailService.initWeb3StorageClient(window.appConfig.web3StorageApiToken);
      // setRecoveryState(RecoveryState.RetrieveFolders);
      // const folders = await window.mailService.getFolders();

      // setRecoveryState(RecoveryState.RecoverySignal);
      // const backup = folders[BACKUP_FOLDER];
      // Object.keys(backup.localStorage).forEach(function (key) {
      //     localStorage.setItem(key, backup.localStorage[key]);
      // });
      // await window.mailService.importIndexedDB(backup.indexedDB);

      // await window.mailService.initSignalService(selectedAccount);
      // setRecoveryState(RecoveryState.Completed);
      // setSetupWizardState(SetupWizardState.Completed);
    } catch (e) {
      window.mailService.isRecovering = false;
      console.error(e);
      showError(e);
      setInitializing(false);
      console.error(e);
    }
  }

  async function emailAuthenticate() {
    let interval = -1;
    let counter = 0;
    try {
      setAuthenticating(true);
      setCreateSpaceStep(1);
      await window.mailService.web3StorageClient.forceReset();
      const client = await window.mailService.web3StorageClient.loadAgent();
      console.log("w3up client:", client);

      const isAuthenticated =
        await window.mailService.web3StorageClient.isAuthenticated();
      if (isAuthenticated) {
        const isSpaceRegistered =
          window.mailService.web3StorageClient.isSpaceRegistered();
        if (!isSpaceRegistered) {
          const spaces = window.mailService.web3StorageClient.spaces();
          if (!spaces || spaces.length === 0) {
            const space =
              await window.mailService.web3StorageClient.createSpace(
                selectedAccount,
                (step, msg) => {
                  if (step !== 1 && interval > 0) {
                    clearInterval(interval);
                    interval = -1;
                  }
                  setCreateSpaceStep(step);
                  setResentMessage(msg);
                }
              );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          } else {
            const space =
              window.mailService.web3StorageClient.getSpaceByName(
                selectedAccount
              ) || spaces[0];
            await window.mailService.web3StorageClient.checkSpace(
              space,
              (step, msg) => {
                if (step !== 1 && interval > 0) {
                  clearInterval(interval);
                  interval = -1;
                }
                setCreateSpaceStep(step);
                setResentMessage(msg);
              }
            );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          }
        }

        setAuthenticating(false);
        return;
      }

      setResentMessage(
        `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
          Strings.web3.w3ui.verify.message_4
        }`
      );
      interval = setInterval(() => {
        counter += 5;
        if (counter >= 60) {
          clearInterval(interval);
          setResentMessage(
            <>
              <Link
                href="#"
                fontWeight={"bold"}
                color={"orange"}
                textDecoration={"underline"}
                onClick={() => {
                  resend();
                  return false;
                }}
              >
                {Strings.web3.w3ui.verify.message_5}
              </Link>
              &nbsp;{Strings.web3.w3ui.verify.message_6}
            </>
          );
        } else {
          setResentMessage(
            `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
              Strings.web3.w3ui.verify.message_4
            }`
          );
        }
      }, 5000);

      await window.mailService.web3StorageClient.authenticate(email);

      const isSpaceRegistered =
        window.mailService.web3StorageClient.isSpaceRegistered();
      if (!isSpaceRegistered) {
        const spaces = window.mailService.web3StorageClient.spaces();
        if (!spaces || spaces.length === 0) {
          const space = await window.mailService.web3StorageClient.createSpace(
            selectedAccount,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                interval = -1;
              }

              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        } else {
          // const space = await window.mailService.web3StorageClient.createSpace(selectedAccount, msg => {
          //   setResentMessage(msg);
          // });
          // window.mailService.web3StorageClient.setCurrentSpace(space.did());
          // await window.mailService.web3StorageClient.register(space);

          const space =
            window.mailService.web3StorageClient.getSpaceByName(
              selectedAccount
            ) || spaces[0];
          await window.mailService.web3StorageClient.checkSpace(
            space,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                interval = -1;
              }
              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );

          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        }
      }
    } catch (err) {
      console.error(err);
      console.log("failed to register: ", err.message || err);
      throw err;
      // if (err.message === "No space selected") {
      //   try {
      //     await actions.createSpace("pleximail");
      //     await actions.registerSpace(email);
      //   } catch (err) {
      //     throw new Error("failed to register", { cause: err });
      //   }
      // } else if (
      //   err.message === "Space already registered with web3.storage."
      // ) {
      //   console.log(err.message);
      // } else if (
      //   err.message.indexOf("no proofs available for resource") !== -1
      // ) {
      //   console.error(err);
      // } else {
      //   throw new Error("failed to register", { cause: err });
      // }
    } finally {
      if (interval !== -1) {
        clearTimeout(interval);
        interval = -1;
      }
      setAuthenticating(false);
    }
  }
  function sharedWeb3Storage() {
    return window.appConfig.web3StorageAccessMethod === Web3StorageAccessMethod.Shared;
  }

  async function importStorage() {

    try {
      setAuthenticating(true);
      setCreateSpaceStep(1);

      // await window.mailService.web3StorageClient.forceReset();
      let client = await window.mailService.web3StorageClient.loadAgent();
      console.log(client);
      const spaces = window.mailService.web3StorageClient.spaces();
      if (spaces && spaces.length > 0) {
        
        if (window.mailService.web3StorageClient.currentSpace()) {
          return;
        }
        const space =
        window.mailService.web3StorageClient.getSpaceByName(
          mailAddressDomain()
        ) || spaces[0];
        
        await window.mailService.web3StorageClient.checkSpace(
          space,
          (step, msg) => {
            setCreateSpaceStep(step);
            setResentMessage(msg);
          }
        );
        window.mailService.web3StorageClient.setCurrentSpace(space.did());
        await window.mailService.web3StorageClient.register(space);
        return;
      }

      let store = await window.mailService.web3StorageClient.requestAuthorization(1);
      if (!store || store.length === 0) {
        setAuthenticating(false);
        return;
      }

      const isSpaceRegistered =
        window.mailService.web3StorageClient.isSpaceRegistered();
      if (!isSpaceRegistered) {
        const spaces = window.mailService.web3StorageClient.spaces();
        if (!spaces || spaces.length === 0) {
          const space = await window.mailService.web3StorageClient.addSpace(store, "base64");
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        } else {
          const space =
            window.mailService.web3StorageClient.getSpaceByName(
              mailAddressDomain()
            ) || spaces[0];
          await window.mailService.web3StorageClient.checkSpace(
            space,
            (step, msg) => {
              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        }
      }
      localStorage.removeItem("app.shared-storage");
      await window.mailService.web3StorageClient.saveSharedEmail();
    } catch (err) {
      console.error(err);
      console.log("failed to register: ", err.message || err);
      throw err;
    } finally {
      setAuthenticating(false);
    }
  }

  async function authenticate() {
    if (sharedWeb3Storage()) {
      await importStorage()
    } else {
      await emailAuthenticate();
    }
  }
  async function automaticRestoreIfNeeded() {
    if (RECOVER_WEB3_CONFIG_AUTOMATICALLY) {
      if (
        SetupWizardState.RestoreWeb3Storage === setupWizardState &&
        window.appConfig.web3StorageApiToken &&
        window.appConfig.web3StorageApiToken.length > 0
      ) {
        if (!window.mailService.isRecovering) {
          try {
            await authenticate();
            await restoreWeb3StorageConfig();
          } catch (e) {
            showError(e);
          } finally {
            setAuthenticating(false);
            setInitializing(false);
          }
        }
      }
    }
  }
  async function nextClicked() {
    try {
      await authenticate();

      if (SetupWizardState.ConfigWeb3Storage === setupWizardState) {
        await saveWeb3StorageConfig();
      } else if (SetupWizardState.RestoreWeb3Storage === setupWizardState) {
        await restoreWeb3StorageConfig();
      }
    } catch (e) {
      console.log("failed to register: ", e.message || e);
      showError(e);
    } finally {
      setAuthenticating(false);
      setInitializing(false);
    }
  }

  const reset = () => {
    
    if (SetupWizardState.ConfigWeb3Storage === setupWizardState) {
      setStorageProvider(StorageProvider.Unspecified);
      return
    }

    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };
  const resend = () => {
    setResentMessage(null);
    setTimeout(() => {
      nextClicked();
    }, 300);
  };

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          {/* {window.wallet &&
            window.wallet.asDefaultWallet &&
            SetupWizardState.ConfigWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.w3ui.step} &nbsp;
              </Text>
            )}
          {window.wallet &&
            window.wallet.asDefaultWallet &&
            SetupWizardState.RestoreWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.w3ui.step} &nbsp;
              </Text>
            )}

          {(!window.wallet || !window.wallet.asDefaultWallet) &&
            SetupWizardState.ConfigWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.config.step} &nbsp;
              </Text>
            )} */}
          {(!window.wallet || !window.wallet.asDefaultWallet) &&
            SetupWizardState.RestoreWeb3Storage === setupWizardState && (
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.web3.config.step_restore} &nbsp;
              </Text>
            )}
          {/* {SetupWizardState.ConfigWeb3Storage === setupWizardState &&
            Strings.web3.config.title}
          {SetupWizardState.RestoreWeb3Storage === setupWizardState &&
            Strings.web3.config.recover_title} */}
            {SetupWizardState.ConfigWeb3Storage === setupWizardState &&
            Strings.web3.storage_selector.title}
          {SetupWizardState.RestoreWeb3Storage === setupWizardState &&
            Strings.web3.storage_selector.recover_title}
        </Heading>

        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.web3.w3ui.content}
        </Text>
          <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
            {/* {Strings.web3.w3ui.note}&nbsp; */}
            {/* <Link style={{color: '#00aaff'}} onClick={tutorialDisclosure.onOpen()} target={"_blank"}>Learn more</Link> */}
            {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
            {/* <TutorialModalLink
              title={"Learn more"}
              anchor={"tokenized-storage"}
            /> */}
            <Link
              style={{ color: "#00aaff" }}
              href={`https://ai-fi.cc/blog-single.html?article=unbearablelightness#tokenized-storage`}
              target={"_blank"}>
              Learn more
            </Link>
            
          </Text>
      </Stack>
      {window.appConfig.web3StorageAccessMethod === Web3StorageAccessMethod.Private &&
      <Box display={"none"} as={"form"} mt="0px" marginTop={"0px"}>
        <Stack spacing={1}>
          <FormLabel htmlFor="emailAddress">{"Email Address"}</FormLabel>
          <Input
            id={"emailAddress"}
            placeholder={"Enter your E-mail address here"}
            defaultValue={email}
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            // onChange={SetupWizardState.RestoreWeb3Storage === setupWizardState ? onChange : () => {}}
            _placeholder={{
              color: "gray.500",
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (MailAddressUtils.isValidEmailAddress(email, false)) {
                  nextClicked();
                }
                return;
              }
            }}
            onChange={(e) => {
              if (MailAddressUtils.isValidEmailAddress(e.target.value, false)) {
                setEmail(e.target.value);
                // if (e.target.value.toLocaleLowerCase() === (window.appConfig.web3StorageApiToken || '').toLocaleLowerCase()) {
                //     actions.loadAgent().then(() => {
                //         setEmail(e.target.value);
                //     }).catch(e => {
                //         console.error(e);
                //     });
                // } else {
                //     actions.unloadAgent().then(() => {
                //         return actions.resetAgent();
                //     }).then(() => {
                //         setEmail(e.target.value);
                //     }).catch(e => {
                //         console.error(e);
                //     });
                // }
              } else {
                setEmail(null);
              }
            }}
          />
          {recoveryState !== RecoveryState.ReadyToRecovery && (
            <HStack>
              <Spinner color={"orange"} size={"sm"} />
              <Text color={"orange"} fontSize={{ base: "sm", sm: "md" }}>
                {progress()}
              </Text>
            </HStack>
          )}

          {isAuthenticating && (
            <>
              <HStack>
                <Spinner color={"#00aaff"} size={"sm"} />
                <Text
                  color={"#00aaff"}
                  fontWeight={"bold"}
                  fontSize={{ base: "lg", sm: "lg" }}
                >
                  {Strings.web3.w3ui.verify.title}
                </Text>
              </HStack>
              {createSpaceStep === 1 && (
                <Text color={"#00aaff"} fontSize={{ base: "sm", sm: "sm" }}>
                  {Strings.web3.w3ui.verify.message_1}&nbsp;{email}&nbsp;
                  {Strings.web3.w3ui.verify.message_2}&nbsp;{resendMessage}
                </Text>
              )}
              {createSpaceStep !== 1 && (
                <Text color={"#00aaff"} fontSize={{ base: "sm", sm: "sm" }}>
                  {resendMessage}
                </Text>
              )}
            </>
          )}

          {/* {isAuthenticating && (!state.space || !state.space.registered())  &&
            <>
            <HStack>

            <Spinner color={'#00aaff'} size={"sm"} />
            <Text color={'#00aaff'} fontWeight={"bold"} fontSize={{ base: 'lg', sm: 'lg' }}>
            {Strings.web3.w3ui.verify.title}
            </Text>
            </HStack>

            <Text color={'#00aaff'} fontSize={{ base: 'sm', sm: 'sm' }}>
            {Strings.web3.w3ui.verify.message_1}&nbsp;{email}&nbsp;{Strings.web3.w3ui.verify.message_2}&nbsp;{resendMessage}
            </Text>
            </>
            }

            {isAuthenticating && (state.space && state.space.registered())  &&
            <HStack>
            <Spinner color={'orange'} size={"sm"} />
            <Text color={'orange'} fontSize={{ base: 'sm', sm: 'md' }}>
            {Strings.web3.w3ui.verify.checking}
            </Text>
            </HStack>
            } */}

          <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
            {Strings.web3.w3ui.note}&nbsp;
            {/* <Link style={{color: '#00aaff'}} onClick={tutorialDisclosure.onOpen()} target={"_blank"}>Learn more</Link> */}
            {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
            <TutorialModalLink
              title={"Learn more"}
              anchor={"private-web3-storage"}
            />
          </Text>
          {/* <Link href='https://web3.storage/docs/#quickstart' target={"_blank"}>Apply for your own Web3 Storage space.</Link> */}
        </Stack>
        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          isDisabled={!MailAddressUtils.isValidEmailAddress(email, false)}
          isLoading={isInitializing || isAuthenticating}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={nextClicked}
        >
          {Strings.web3.config.button.next.title}
        </Button>

        {/* {window.wallet && window.wallet.asDefaultWallet &&
        <ConfirmationCloseButton fontFamily={'heading'}
        mt={4}
        w={'full'}
        bgColor={"gray.200"}
        // bgGradient="linear(to-r, red.400,pink.400)"
        color={'black'}
        // _hover={{
        //     bgGradient: 'linear(to-r, red.400,pink.400)',
        //     boxShadow: 'xl',
        // }}
        isDisabled={isInitializing || isAuthenticating}
        onClick={() => {
            backClicked();
        }}>
        {Strings.web3.config.button.back.title}
        </ConfirmationCloseButton>
        } */}

        {window.wallet &&
          window.wallet.asDefaultWallet &&
          SetupWizardState.RestoreWeb3Storage === setupWizardState && (
            <ConfirmationCloseButton
              fontFamily={"heading"}
              mt={4}
              w={"full"}
              bgColor={"gray.200"}
              // bgGradient="linear(to-r, red.400,pink.400)"
              color={"black"}
              // _hover={{
              //     bgGradient: 'linear(to-r, red.400,pink.400)',
              //     boxShadow: 'xl',
              // }}
              isDisabled={isInitializing || isAuthenticating}
              onClick={() => {
                reset();
              }}
            >
              {Strings.web3.config.button.back.title}
            </ConfirmationCloseButton>
          )}
        {(!window.wallet ||
          !window.wallet.asDefaultWallet ||
          SetupWizardState.RestoreWeb3Storage !== setupWizardState) && (
          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgColor={"gray.200"}
            // bgGradient="linear(to-r, red.400,pink.400)"
            color={"black"}
            // _hover={{
            //     bgGradient: 'linear(to-r, red.400,pink.400)',
            //     boxShadow: 'xl',
            // }}
            disabled={isInitializing || isAuthenticating}
            onClick={() => {
              reset()
            }}
          >
            {Strings.web3.config.button.back.title}
          </Button>
        )}
      </Box>
      }
      

      {window.appConfig.web3StorageAccessMethod === Web3StorageAccessMethod.Shared &&
      <Box as={"form"} mt="0px" marginTop={"0px"}>
        <Stack spacing={1}>
          
          {recoveryState !== RecoveryState.ReadyToRecovery && (
            <HStack>
              <Spinner color={"orange"} size={"sm"} />
              <Text color={"orange"} fontSize={{ base: "sm", sm: "md" }}>
                {progress()}
              </Text>
            </HStack>
          )}

          {isAuthenticating && (
              <HStack>
                <Spinner color={"#00aaff"} size={"sm"} />
                <Text
                  color={"#00aaff"}
                  fontWeight={"bold"}
                  fontSize={{ base: "lg", sm: "lg" }}
                >
                  {Strings.web3.w3ui.shared.config_storage}
                </Text>
              </HStack>
          )}
        </Stack>

        {window.wallet &&
          window.wallet.asDefaultWallet &&
          SetupWizardState.RestoreWeb3Storage === setupWizardState && (
            <ConfirmationCloseButton
              fontFamily={"heading"}
              mt={4}
              w={"full"}
              bgColor={"gray.200"}
              // bgGradient="linear(to-r, red.400,pink.400)"
              color={"black"}
              // _hover={{
              //     bgGradient: 'linear(to-r, red.400,pink.400)',
              //     boxShadow: 'xl',
              // }}
              isDisabled={isInitializing || isAuthenticating}
              onClick={() => {
                reset();
              }}
            >
              {Strings.web3.config.button.back.title}
            </ConfirmationCloseButton>
          )}

        {(!window.wallet ||
          !window.wallet.asDefaultWallet ||
          SetupWizardState.RestoreWeb3Storage !== setupWizardState) && (
          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgColor={"gray.200"}
            // bgGradient="linear(to-r, red.400,pink.400)"
            color={"black"}
            // _hover={{
            //     bgGradient: 'linear(to-r, red.400,pink.400)',
            //     boxShadow: 'xl',
            // }}
            disabled={isInitializing || isAuthenticating}
            onClick={() => {
              reset()
            }}
          >
            {Strings.web3.config.button.back.title}
          </Button>
        )}
      </Box>
      } 
      
    </>
  );
}

export default function Web3StorageConfigScreen({
  setSetupWizardState,
  setupWizardState,
  folders,
  setFolders,
  selectedAccount,
}) {
  
  const [storageProvider, setStorageProvider] = useState(window.appConfig.storageProvider);
  useEffect(() => {
    // if (storageProvider === StorageProvider.Unspecified) {
    //   setStorageProviderWapper(StorageProvider.Web3Storage);
    // }
  }, [])
  const setStorageProviderWapper = (provider) => {
    setStorageProvider(provider);
    window.appConfig.storageProvider = provider;
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          {storageProvider === StorageProvider.Unspecified &&
            <Web3StorageProviderSelector 
              setSetupWizardState={setSetupWizardState}
              setupWizardState={setupWizardState}
              setStorageProvider={setStorageProviderWapper}  />
          }

          {storageProvider === StorageProvider.LightHouse &&
            <Web3StorageLighthouseConfigurator
              setSetupWizardState={setSetupWizardState}
              setupWizardState={setupWizardState}
              selectedAccount={selectedAccount}
              setStorageProvider={setStorageProviderWapper}
            />
          }
          {storageProvider === StorageProvider.Web3Storage &&
            <Web3StorageW3UIConfigurator
              setSetupWizardState={setSetupWizardState}
              setupWizardState={setupWizardState}
              selectedAccount={selectedAccount}
              setStorageProvider={setStorageProviderWapper}
            />
          }
        </Stack>
      </Container>
    </Box>
  );
}
